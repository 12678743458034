<ng-container *ngIf="!hideHeader && !richmeeHeader && !kandkfinservHeader &&!nirjainvestmentsHeader">
  <div class="header-wrapper" [ngClass]="!bgColor ? 'applyBg' : 'removeBg'">
    <div class="heder-inner">
      <div class="container-lg row" style="margin: auto">
        <div class="col-sm-3 col-md-3">
          <div class="logo-wrapper pb-0 w-260">
            <span>
              <!-- <img src="../../../../../assets/images/logo-update.svg" /> -->
              <img
                (click)="goToHome()"
                style="cursor: pointer"
                src="../../../../../assets/images/icons/main_Logo.svg" />
            </span>
            <div
              class="mainlogoTexttContainer fw-500 py-1 f-10 offset-md-3 offset-0 text-nowrap font-8 mt--2">
              Powered by
              <span class="linkStyle"
                ><a target="_blank" href="https://lamronanalysts.com/"> Lamron Analysts</a>&nbsp;
              </span>
              <br class="showBreak" />
              SEBI Registration No: INA300003772
            </div>
          </div>
        </div>
        <div class="col-sm-9 col-md-9">
          <div class="menu f-16 fw-600 white" style="line-height: 30px">
            <ul>
              
              <li *ngIf="false">
                <form #myForm method="post" [action]="environment.baseUrl_8083 + 'smartvalues/func/show_pouch'" ngNoForm >
                  <input placeholder="id" name="pouchId" [(ngModel)]="pouchId" />
                  <input  placeholder="token" name="token" [(ngModel)]="token" />
                  <button type="submit" style="width: 100px;">Submit</button>
                </form>
              
              </li>
              <li class="choice-btn login ml-30">
                <button (click)="onLogin()">Login</button>
              </li>
              <li class="choice-btn ml-15">
                <button (click)="onRegister()">Register</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="richmeeHeader">
  <div class="row white-bg position-relative minH-90 align-items-center">
    <div class="col-2 offset-1" style="width: 125px">
      <img class="" src="../../../../../assets/images/logo/richmee.png" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="kandkfinservHeader">
  <div class="row white-bg position-relative minH-90 align-items-center">
    <div class="col-2 offset-1" style="width: 125px">
      <img class="" src="../../../../../assets/images/logo/kandkfinserv.jpg" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="nirjainvestmentsHeader">
  <div class="row white-bg position-relative minH-90 align-items-center">
    <div class="col-2 offset-1" style="width: 125px">
      <img class="" src="../../../../../assets/images/logo/nirjainvestments_logo.jpg" />
    </div>
  </div>
</ng-container>
