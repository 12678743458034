import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PouchService {
  private GET_TIMEFRAMES = `get_timeframes`;
  private GET_UNIVERSE_LIST = `store/smartvalues/myquery/execute/get_universe_list`;
  private GET_POUCH_LIST = `store/smartvalues/myquery/execute/get_pouchlist`;
  private GET_STOCKS_BY_UNIVERSE = `store/smartvalues/myquery/execute/get_stocks_by_universe`;
  private CREATE_POUCH = `store/smartvalues/myquery/execute/create_pouch`;
  private EDIT_POUCH = `store/smartvalues/myquery/execute/edit_pouch`;
  private GET_POUCH_STOCKS_RETURN = `store/smartvalues/myquery/execute/get_pouch_stocks_returns`;
  private COMPARE_POUCH_UNIVERSE_PERFORMANCE = `store/smartvalues/myquery/execute/compare_pouch_universe_performance`;
  private GET_POUCH_INDEX_RATIO = `store/smartvalues/myquery/execute/get_pouch_index_ratios`;
  private GET_POUCH_SUBSCRIBER_STOCKS_RETURNS = `store/smartvalues/myquery/execute/get_pouch_subscriber_stocks_returns`;
  private COMPARE_POUCH_SUBSCRIBER_UNIVERSE_PERFORMANCE = `store/smartvalues/myquery/execute/compare_pouch_subscriber_universe_performance`;
  private STOP_LOSS = `store/smartvalues/myquery/execute/fetch_stop_loss`;
  private FETCH_WEALTHY_ORDER = `smartvalues/func/fetch_wealthy_order`;
  private FETCH_BAJAJ_ORDER = `smartvalues/func/bajaj_fetch_order`;
  private FETCH_STOCK_TRADE_PRICE_WEALTHY ='smartvalues/func/fetch_stock_trade_price_wealthy'
  private FETCH_STOCK_TRADE_PRICE_BAJAJ = 'smartvalues/func/fetch_stock_trade_price_bajaj'
  private FETCH_STOCK_TRADE_PRICE_WEALTHY_NO_POUCH ='smartvalues/func/fetch_stock_trade_price_wealthy_no_pouch'
  private FETCH_STOCK_TRADE_PRICE_BAJAJ_NO_POUCH ='smartvalues/func/fetch_stock_trade_price_bajaj_no_pouch'
  private FETCH_WEALTHY_TRANSACTIONS_SMARTSTOCKS = 'smartvalues/func/get_wealthy_txnid_np/';
  private FETCH_BAJAJ_TRANSACTIONS_SMARTSTOCKS ='smartvalues/func/get_bajaj_txnid_np'
  private LIQUIDATE_SUBSCRIBER_POUCH ='smartvalues/func/liquidate_pouch_subscriber'
  private UNSUBSCRIBE_SUBSCRIBER_POUCH ='smartvalues/func/unsubscribe_subscriber_pouch'
  private FETCH_VARIABLE_POUCH_PRICE_SUBSCRIBERS_LIST = 'smartvalues/func/fetch_variable_pouch_price_subscribers_list'
  private FETCH_DEFAULT_POUCH_PRICE_SUBSCRIBERS = 'smartvalues/func/fetch_default_pouch_price_subscribers'
  private SAVE_SUBSCRIBER_SUBSCRIPTIONS ='smartvalues/func/save_subscriber_subscriptions'
  private REMOVE_SUBSCRIBER_SUBSCRIPTION = 'smartvalues/func/remove_subscriber_subscription'
  
  constructor(private http: HttpClient) {}

  gatewayInstance = this.getSmallcase();
  getSmallcase() {
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    try {
      if (subdomain === 'richmee' || subdomain == 'kandkfinserv' || subdomain == 'nirjainvestments') {
        return {};
      }
      return new window.scDK({
        // ! Use valid gateway name here
        gateway: 'smartvalues',
        /*
         * ! Use valid auth token
         * Details - https://developers.gateway.smallcase.com/docs/getting-started#broker-identification
         */
        smallcaseAuthToken: environment.smallcaseAuthToken,
        config: {
          // Should allow after market orders?
          amo: true
        }
      });
      
    } catch (error) {
      
    }
  }

  getPouchList(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_LIST, data);
  }

  getUniverseList() {
    return this.http.post(environment.baseUrl + this.GET_UNIVERSE_LIST, {});
  }
  getUniverseListNew(payload: any) {
    return this.http.post(environment.baseUrl + this.GET_UNIVERSE_LIST, payload);
  }

  generateTimeframe(data: any) {
    return this.http.post(environment.baseUrl_8081 + this.GET_TIMEFRAMES, data).toPromise();
  }

  getStockListByUniverse(data: any) {
    return this.http.post(environment.baseUrl + this.GET_STOCKS_BY_UNIVERSE, data);
  }

  createPouch(data: any) {
    return this.http.post(environment.baseUrl + this.CREATE_POUCH, data);
  }

  editPouch(data: any) {
    return this.http.post(environment.baseUrl + this.EDIT_POUCH, data);
  }

  getPouchStocksReturns(data: any) {
    // data.pouch_id =277
    // data.stock_price_date="2022-07-01"
    return this.http.post(environment.baseUrl + this.GET_POUCH_STOCKS_RETURN, data);
  }

  getComparePouchUniversePerformance(data: any) {
    return this.http.post(environment.baseUrl + this.COMPARE_POUCH_UNIVERSE_PERFORMANCE, data);
  }

  getPouchIndexRatio(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_INDEX_RATIO, data);
  }

  getPouchSubscriberStocksReturns(data: any) {
    return this.http.post(environment.baseUrl + this.GET_POUCH_SUBSCRIBER_STOCKS_RETURNS, data);
  }
  fetchWealthyOrder(data: any) {
    return this.http.post(environment.baseUrl_8083 + this.FETCH_WEALTHY_ORDER, data);
  }
  fetchBajajOrder(data: any) {
    return this.http.post(environment.baseUrl_8083 + this.FETCH_BAJAJ_ORDER, data);
  }

  getComparePouchSubscriberUniversePerformance(data: any) {
    return this.http.post(
      environment.baseUrl + this.COMPARE_POUCH_SUBSCRIBER_UNIVERSE_PERFORMANCE,
      data
    );
  }
  getTransactionId(body: any) {
    return this.http.post(environment.baseUrl_8083 + `smartvalues/func/get_sc_txnid`, body);
  }
  selectedSubscriber: any;
  getSubscriberSubscription(body: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/get_subscriber_subscription`,
      body
    );
  }

  deletePouch(payload: any) {
    return this.http.post(
      environment.baseUrl + `store/smartvalues/myquery/execute/delete_pouch`,
      payload
    );
  }
  updateManualTxn(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/insert_manual_txn',
      payload
    );
  }
  fetchValidStockPrice(payload: any) {
    return this.http.post(
      environment.baseUrl_8083 + 'smartvalues/func/fetch_valid_stock_price',
      payload
    );
  }
  downloadRebalance(payload: any, type: any) {
    return this.http.post(
      environment.baseUrl +
        `store/smartvalues/myquery/execute/${type === 'AVERAGE' ? 'download_rebalance_details_avg' : 'download_rebalance_details'}/csv`,
      payload,
      { responseType: 'text' }
    );
  }
  downloadReturnSummary(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/download_return_summary/csv',
      payload,
      { responseType: 'text' }
    );
  }
  downloadReturnDetails(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/download_return_details/csv',
      payload,
      { responseType: 'text' }
    );
  }

  get_pouch_subscriber_status(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/get_pouch_subscriber_status',
      payload
    );
  }
  deleteTxn(payload: any) {
    return this.http.post(
      environment.baseUrl + 'store/smartvalues/myquery/execute/delete_txn',
      payload
    );
  }
  getPouchCreationId(body: any) {
    return this.http.post(environment.baseUrl_8083 + `smartvalues/func/get_sc_txnid_np/`, body);
  }
  postStopLoss(data: any) {
    return this.http.post(environment.baseUrl + this.STOP_LOSS, data);
  }
  getOrderDetails(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/get_wealthy_order`, data);
  }
  getOrderDetailsBajaj(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/get_bajaj_order`, data);
  }
  fetch_stock_trade_price_wealthy(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_STOCK_TRADE_PRICE_WEALTHY, data);
  }
  fetch_stock_trade_price_bajaj(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_STOCK_TRADE_PRICE_BAJAJ, data);
  }
  fetch_stock_trade_price_wealthy_no_pouch(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_STOCK_TRADE_PRICE_WEALTHY_NO_POUCH, data);
  }
  fetch_stock_trade_price_bajaj_no_pouch(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_STOCK_TRADE_PRICE_BAJAJ_NO_POUCH, data);
  }
  fetchWealthyTransactionSmartStocks(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_WEALTHY_TRANSACTIONS_SMARTSTOCKS, data);
  }
  fetchBajajTransactionSmartStocks(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_BAJAJ_TRANSACTIONS_SMARTSTOCKS, data);
  }

  liquidatePouch(body:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.LIQUIDATE_SUBSCRIBER_POUCH, body);

  }
  unSubscribePouch(body:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.UNSUBSCRIBE_SUBSCRIBER_POUCH, body);

  }

  fetch_variable_pouch_price_subscribers_list(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_VARIABLE_POUCH_PRICE_SUBSCRIBERS_LIST, data)
  }
  fetch_default_pouch_price_subscribers(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.FETCH_DEFAULT_POUCH_PRICE_SUBSCRIBERS, data)
  }
  save_subscriber_subscription(data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.SAVE_SUBSCRIBER_SUBSCRIPTIONS, data)
  }
  remove_subscriber_subscription (data:any){
    return this.http.post(`${environment.baseUrl_8083}`+this.REMOVE_SUBSCRIBER_SUBSCRIPTION, data)
  }
  get_Referrer_User_Subscribers(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/get_referrer_user_subscribers/`, data);
  }
  Add_Subscriber_Referrer_User(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/add_subscriber_referrer_user/`, data);
  }
  Remove_Subscriber_Referrer_User(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/remove_subscriber_referrer_user/`, data);
  }
  get_referrer_user_rank_by_total_investment(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/get_referrer_user_rank_tot_inv`, data);
  }
  get_referrer_user_rank_by_total_subscriber_count(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/get_referrer_user_rank_tot_sub_cnt`, data);
  }
  get_invite_subscribers(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/get_invite_subs`,data)
  }
  insert_invite_subscribers(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/save_invite_subscribers`,data)
  }
  delete_invite_subscriber(data:any){
    return this.http.post(`${environment.baseUrl_8083}smartvalues/func/delete_invite_sub`,data)
  }

}
