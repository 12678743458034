import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeIn from '@angular/common/locales/en-IN';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { UserIdleModule } from 'angular-user-idle';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxHotjarModule } from 'ngx-hotjar';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from 'src/app/core/auth.interceptor';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { TimePopupComponent } from './common/components/time-popup/time-popup.component';
import { appInitializer } from './common/helpers/app.initializer';
import { LoggerService } from './common/services/logger.service';
import { DashboardTableComponent } from './directives/dashboard-table.component';
// import { DetailsComponent } from './modules/investment-pouches/details/details.component';
import { DashboardTableDirective } from './directives/dashboard-table.directive';
import { ImageDragDirective } from './directives/image-drag.directive';
import { FooterComponent } from './footer/footer.component';
import { HeaderPublicComponent } from './header-public/header-public.component';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './modules/auth/services/auth.service';
import { AuthEffects } from './modules/auth/store/effects/auth.effect';
import * as auth from './modules/auth/store/reducers/auth.reducer';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { DashboardEffects } from './modules/dashboard/store/effects/dashboard.effect';
import * as dashboard from './modules/dashboard/store/reducers/dashboard.reducer';
import { InvestmentPouchesComponent } from './modules/investment-pouches/investment-pouches.component';
import { PouchEffects } from './modules/investment-pouches/store/effects/pouch.effect';
import { UniverseEffects } from './modules/investment-pouches/store/effects/universe.effect';
import * as pouch from './modules/investment-pouches/store/reducers/pouch.reducer';
import * as universe from './modules/investment-pouches/store/reducers/universe.reducer';
import { InvestmentComponent } from './modules/investment/investment.component';
import { NavigationComponent } from './modules/navigation/navigation.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { PublicEffect } from './modules/public/state/public.effects';
import { PublicFacadeService } from './modules/public/state/public.facade.services';
import { publicReducer } from './modules/public/state/public.reducers';
import { ReportsComponent } from './modules/reports/reports.component';
import { SharedModule } from './modules/shared/shared.module';
import { PreloaderComponent } from './preloader/preloader.component';
import { reducers, metaReducers } from './store/reducers';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { WarningKycRpPopupComponent } from './warning-kyc-rp-popup/warning-kyc-rp-popup.component';
import { PublicService } from './modules/public/services/public.service';
import { SharedService } from './common/services/shared.service';
import { RedirectComponent } from './common/redirect/redirect.component';
import { RedirectInterceptor } from './core/redirecr.interceptor';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatTableModule } from '@angular/material/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

registerLocaleData(localeIn);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    InvestmentPouchesComponent,
    InvestmentComponent,
    DashboardComponent,
    ReportsComponent,
    // DetailsComponent,
    DashboardTableDirective,
    DashboardTableComponent,
    FooterComponent,
    CommonDialogComponent,
    ImageDragDirective,
    HeaderPublicComponent,
    ToastNotificationComponent,
    WarningKycRpPopupComponent,
    PreloaderComponent,
    ProfileComponent,
    TimePopupComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatBadgeModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatDividerModule,
    MatMenuModule,
    MatDialogModule,
    CommonModule,
    SharedModule,
    MatButtonModule,
    NgOtpInputModule,
    InfiniteScrollModule,
    MatTableModule,
    MatAutocompleteModule,
    StoreModule.forFeature('PUBLIC', publicReducer),
    StoreModule.forRoot(
      {
        auth: auth.reducer,
        pouch: pouch.reducer,
        dashboard: dashboard.reducer,
        universe: universe.reducer,
        reducers
      },
      { metaReducers }
    ),
    EffectsModule.forRoot([
      AuthEffects,
      PouchEffects,
      DashboardEffects,
      UniverseEffects,
      PublicEffect
    ]),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    UserIdleModule.forRoot({
      idle: environment.idleTime,
      timeout: environment.timeout,
      ping: 200
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, PublicFacadeService,PublicService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
      // deps: [AuthService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: RedirectInterceptor, multi: true },
    LoggerService,
    PublicFacadeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
