<div class="question-wrapper">
  <div class="chat_screen">
    <div class="questions_container" *ngIf="templateData">
      <ul>
        <li class="d-flex">
          <img class="bot_icon" src="../../../../../../assets/images/icons/question.svg" alt="" />
          <div class="question_1 f-16" style="padding: 22px 13px 20px 28px">
            {{ templateData.question }}
          </div>
        </li>
      </ul>
    </div>
    <div class="button-container d-flex manual_update_container" *ngIf="showupload">
      <!-- <div class="button-Subcontainer flex-property pointer" (click)="getFromDigi($event)">
        <div class="subcontainer-img">
          <img src="../../../../../../assets/images/kyc_upload-digiLocker-Medium.png" alt="" />
        </div>

        <p class="subContainerText text-center">(We have partnered with Decentro)</p>
      </div> -->
      <div
        class="button-Subcontainer flex-property "
        (click)="changeuploard()">
        <div class="uploadImg">
          <img src="../../../../../../assets/images/uploadkyc.png" alt="" />
        </div>

        <p class="subContainerText">Manual Upload</p>
      </div>
    </div>
    <div class=" row " style="margin: top -20px" *ngIf="!showupload">
      <ng-container>
        <!-- <div class="d-flex"> -->
        <div class="col-lg-6 mt-30 allignment" *ngIf="showAadhar">
          <div>
            <p class="mb-8 document_name">Aadhar (Front Side)</p>
            <ngx-dropzone
              accept="image/png, image/jpeg,application/pdf"
              class="dnd-container"
              id="AADHAR"
              (change)="onSelect($event)">
              <!-- <div #ngxAadharDropzone></div> -->
              <ngx-dropzone-label class="mt-14" *ngIf="!aadhar || !uploadAadhar">
                <p class="dnd_text">Drag and drop file here</p>
                <p class="dnd_text">OR</p>
                <p class="mt-11 browse-btn">Browse</p>
                <p class="font-grey mt-7 pb-16">(Supported files : jpeg, png, .pdf)</p>
              </ngx-dropzone-label>
              <div *ngIf="aadhar && uploadAadhar" class="w_100">
                <embed [src]="aadhar.file" frameborder="0" height="200px" class="embed_width" />
                <!-- <div style="
                color: #065dbc;
                margin-left: auto;
                float: right;
                margin-right: 12%;
                cursor: pointer;
              "> Update</div>               -->
              </div>
            </ngx-dropzone>
            <div class="mt-2" *ngIf="aadhar">
              Uploaded on {{ aadhar?.created_date | date: 'dd MMM yyyy'
              }}<span
                style="color: #065dbc; cursor: pointer; text-decoration: underline"
                (click)="updateAadhar()">
                Update</span
              >
            </div>
            <ng-container>
              <div>
                <div *ngIf="uploadFileProcess.aadhar.showProgressForAadhar">
                  <div class="p-flex pt-1">
                    <mat-progress-bar
                      class="mat-progress-bar"
                      mode="determinate"
                      [value]="uploadFileProcess.aadhar.progressValueForAadhar">
                    </mat-progress-bar>
  
                    <img
                      [id]="uploadFileProcess.aadhar.aadharFileName"
                      class="close_icon"
                      (click)="clearAdharFile($event)"
                      src="../../../../../../assets/images/icons/icons8_cancel.svg"
                      alt="" />
                  </div>
                  <p class="fileName_text">
                    {{ uploadFileProcess.aadhar.aadharFileName }}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-6 mt-30 allignment" *ngIf="showAadhar_b">
          <div>
            <p class="mb-8 document_name">Aadhar (Back Side)</p>
            <ngx-dropzone
              accept="image/png, image/jpeg,application/pdf"
              class="dnd-container"
              id="AADHAR_B"
              (change)="onSelect($event)">
              <!-- <div #ngxAadharDropzone></div> -->
              <ngx-dropzone-label class="mt-14" *ngIf="!aadhar_b || !uploadAadhar_b">
                <p class="dnd_text">Drag and drop file here</p>
                <p class="dnd_text">OR</p>
                <p class="mt-11 browse-btn">Browse</p>
                <p class="font-grey mt-7 pb-16">(Supported files : jpeg, png, .pdf)</p>
              </ngx-dropzone-label>
              <div *ngIf="aadhar_b && uploadAadhar_b" class="w_100">
                <embed [src]="aadhar_b.file" frameborder="0" height="200px" class="embed_width" />
              </div>
            </ngx-dropzone>
            <div class="mt-2" *ngIf="aadhar_b">
              Uploaded on {{ aadhar_b?.created_date | date: 'dd MMM yyyy'
              }}<span
                style="color: #065dbc; cursor: pointer; text-decoration: underline"
                (click)="updateAadhar_b()">
                Update</span
              >
            </div>
            <ng-container>
              <div>
                <div *ngIf="uploadFileProcess.aadhar_b.showProgressForAadhar_b">
                  <div class="p-flex pt-1">
                    <mat-progress-bar
                      class="mat-progress-bar"
                      mode="determinate"
                      [value]="uploadFileProcess.aadhar_b.progressValueForAadhar_b">
                    </mat-progress-bar>
  
                    <img
                      [id]="uploadFileProcess.aadhar_b.aadhar_bFileName"
                      class="close_icon"
                      (click)="clearAdhar_bFile($event)"
                      src="../../../../../../assets/images/icons/icons8_cancel.svg"
                      alt="" />
                  </div>
                  <p class="fileName_text">
                    {{ uploadFileProcess.aadhar_b.aadhar_bFileName }}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-6 mt-30 allignment" *ngIf="showPan">
          <div>
            <p class="mb-8 document_name">PAN</p>

            <ngx-dropzone
              accept="image/png, image/jpeg,application/pdf"
              id="PAN"
              class="dnd-container"
              (change)="onSelect($event)">
              <!-- <div #ngxPanDropzone></div> -->
              <ngx-dropzone-label class="mt-14" *ngIf="!pan || !uploadPan">
                <p class="dnd_text">Drag and drop file here</p>
                <p class="dnd_text">OR</p>
                <p class="mt-11 browse-btn">Browse</p>
                <p class="font-grey mt-7 pb-16">(Supported files : jpeg, png, .pdf)</p>
              </ngx-dropzone-label>
              <div *ngIf="pan && uploadPan" class="w_100">
                <embed [src]="pan?.file" frameborder="0" height="200px" class="embed_width" />
              </div>
            </ngx-dropzone>
            <div class="mt-2" *ngIf="pan">
              Uploaded on {{ pan?.created_date | date: 'dd MMM yyyy' }}
              <span
                style="color: #065dbc; cursor: pointer; text-decoration: underline"
                (click)="updatePan()">
                Update</span
              >
            </div>
            <ng-container>
              <div>
                <div *ngIf="uploadFileProcess.pan.showProgressForPan">
                  <div class="p-flex pt-1">
                    <mat-progress-bar
                      class="mat-progress-bar"
                      mode="determinate"
                      [value]="uploadFileProcess.pan.progressValueForPan">
                    </mat-progress-bar>
  
                    <img
                      class="close_icon"
                      [id]="uploadFileProcess.pan.panFileName"
                      (click)="clearPanFile($event)"
                      src="../../../../../../assets/images/icons/icons8_cancel.svg"
                      alt="" />
                  </div>
                  <p class="fileName_text">
                    {{ uploadFileProcess.pan.panFileName }}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-6 btn_save_style" style="margin-top: auto">
          <button
            #btn2
            mat-button
            [ngClass]="{ next_btn: type !== 'subscriber' }"
            class="theme-btn primary-btn"
            (click)="completeQuestionare(btn2)"
            [disabled]="
              !(
                (uploadFileProcess.pan.isPanUploaded &&
                  uploadFileProcess.aadhar_b.isAadhar_bUploaded &&
                  uploadFileProcess.aadhar.isAadharUploaded) ||
                enableButtton ||
                (pan && AadharUploaded && Aadhar_bUploaded) ||
                (aadhar && aadhar_b && panUploded) ||
                (pan && aadhar && aadhar_b)
              )
            ">
            Save
          </button>
        </div>

        <!-- </div> -->
      </ng-container>
    </div>
    <div *ngIf="buttonShow && hideButton">
      <div class="user_input-m" *ngIf="isMobile">
        <button mat-button class="theme-btn border-btn" (click)="back()">Close</button>
        <ng-container *ngIf="!showSaveBtn">
          <button
            #btn
            mat-button
            class="theme-btn primary-btn next_btn"
            (click)="completeQuestionare(btn)"
            [disabled]="
              !(
                (uploadFileProcess.pan.isPanUploaded &&
                  uploadFileProcess.aadhar_b.isAadhar_bUploaded &&
                  uploadFileProcess.aadhar.isAadharUploaded) ||
                enableButtton ||
                (pan && AadharUploaded && Aadhar_bUploaded) ||
                (aadhar && aadhar_b && panUploded) ||
                (pan && aadhar && aadhar_b)
              )
            ">
            Save
            <img
              class=">arrow"
              [ngStyle]="{
                opacity: !(
                  uploadFileProcess.pan.isPanUploaded &&
                  uploadFileProcess.aadhar.isAadharUploaded &&
                  uploadFileProcess.aadhar_b.isAadhar_bUploaded
                )
                  ? 0.26
                  : 1
              }"
              src="../../../../../assets/images/icons/next_arrow.svg" />
          </button>
        </ng-container>
        <ng-container *ngIf="showSaveBtn">
          <button
            #btn2
            mat-button
            style="width: 70px"
            [ngClass]="{ next_btn: type !== 'subscriber' }"
            class="theme-btn primary-btn spinnerBtn">
            <mat-icon><mat-spinner color="primary" diameter="20"> </mat-spinner></mat-icon>
          </button>
        </ng-container>
      </div>
      <div *ngIf="!isMobile">
        <div class="footer_btn">
          <button
            mat-button
            class="theme-btn border-btn"
            (click)="back()"
            *ngIf="type !== 'subscriber'">
            <!-- <img
            src="../../../../../../assets/images/icons/back_vector.svg"
            alt=""
          /> -->
            Close
          </button>
          <ng-container *ngIf="!showSaveBtn">
            <button
              #btn2
              mat-button
              [ngClass]="{ next_btn: type !== 'subscriber' }"
              class="theme-btn primary-btn"
              (click)="completeQuestionare(btn2)"
              [disabled]="
                !(
                  (uploadFileProcess.pan.isPanUploaded &&
                    uploadFileProcess.aadhar_b.isAadhar_bUploaded &&
                    uploadFileProcess.aadhar.isAadharUploaded) ||
                  enableButtton ||
                  (pan && AadharUploaded && Aadhar_bUploaded) ||
                  (aadhar && aadhar_b && panUploded) ||
                  (pan && aadhar && aadhar_b)
                )
              ">
              Save
            </button>
          </ng-container>
          <ng-container *ngIf="showSaveBtn">
            <button
              #btn2
              mat-button
              style="width: 70px"
              [ngClass]="{ next_btn: type !== 'subscriber' }"
              class="theme-btn primary-btn spinnerBtn">
              <mat-icon><mat-spinner color="primary" diameter="20"> </mat-spinner></mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
