import { Component, Inject, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { cloneDeep } from 'lodash';
import * as moment from 'moment';

import { PouchService } from '../../investment-pouches/services/pouch.service';
import { NotificationService } from 'src/app/services/notification.services';
import { CurrentTradeValuePipe } from './current-trade.pipe';

@Component({
  selector: 'app-execute-trade-popup',
  templateUrl: './execute-trade-popup.component.html',
  styleUrls: ['./execute-trade-popup.component.scss'],
  providers: [CurrentTradeValuePipe]

})
export class ExecuteTradePopupComponent implements OnInit {
  tooltipText = 'Option will be available once all "Placed" transaction status updated.'
  @Input() pouchId: any;
  Number = Number
  settingForm: FormGroup;
  since: string | number | Date;
  getData: any;
  current_trade_value: number = 0;
  initialCurrentTradeValue = 0
  broker_balance: number = 0;
  review_data: boolean = false
  stockData: any = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pouchService: PouchService,
    private fb: FormBuilder,
    private notifyService: NotificationService,
    private matDialogRef: MatDialogRef<ExecuteTradePopupComponent>,
    private currentTradeValue: CurrentTradeValuePipe
  ) { }
  show_ghost:boolean = true
  ngOnInit(): void {
    this.stockData = this.data.data;
    if (this.data.partner == 'wealthy') {
      this.fetchWealthyTradePrice()
    } else if (this.data.partner == 'bajajbroking') {
      this.fetchBajajTradePrice()
    }

    this.getData = cloneDeep(this.data);
    this.data.current_investment = 10000

    this.settingForm = this.fb?.group({
      capacity: new FormControl('', [Validators.required])
    });
  }
  save() {
    let payload: any = {
      pouch_id: this.getData?.pouch_id,
      subscriber_id: this.getData?.subscriber_id
    };
    if (this.data.partner == 'wealthy') {

      let orders = this.stockData
        .map((e: any) => {
          return {
            quantity: e.stock_qty,
            trading_symbol: `${e.stock_code}-EQ`,
            trade_id: this.data.from == 'smart-stocks' ? e.pouch_sub_trade_ss_id : e.id,
            "transaction_type": e.txn_type === 'BUY' ? 1 : 2

          };
        })
        ?.filter((e: any) => e.quantity > 0);
      payload['orders'] = orders;
      this.pouchService.fetchWealthyOrder(payload).subscribe((res: any) => {

        this.matDialogRef.close(true)
      }, (e) => {
        this.notifyService.showError('', e.error.error.message);
      });
    } else if (this.data.partner == 'bajajbroking') {
      let orders = this.stockData
        .map((e: any) => {
          return {
            // "exchange": "NSE",
            // "token_symbol": e.stock_token,
            // "order_type": "RL-M",
            "buy_sell": e?.txn_type,
            // "product": "N",
            "qty": e.stock_qty,
            // "limit_price": e.close_price,
            // "sl_price": 0,
            // "disc_qty": 0,
            // "validity": "DAY",
            // "amo": "NO",
            symbol: e.stock_code,
            "trade_id": this.data.from == 'smart-stocks' ? e.pouch_sub_trade_ss_id : e.id
          }
        })
        ?.filter((e: any) => e?.quantity > 0 || e?.qty > 0);
      payload['orders'] = orders;
      this.pouchService.fetchBajajOrder(payload).subscribe((res: any) => {


        let body = {
          "subscriber_id": JSON.parse(localStorage.getItem('user') || '{}').id,
          from_date: new Date(
            new Date(
              localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
            ).setDate(
              new Date(
                localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
              ).getDate() - 7
            )
          )
            .toISOString()
            .split('T')[0],
          to_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
          pouch_id: this.getData?.pouch_id,
        }

        this.pouchService.getOrderDetailsBajaj(body).subscribe((res: any) => {
          this.matDialogRef.close(true);
        }, (e) => {
          this.notifyService.showError('', e.error.error.message);
        });

      }, (e) => {
        this.notifyService.showError('', e.error.error.message);
      });
    }
  }
  increment(i: any) {
    let qnt = this.stockData[i].stock_qty
    if (this.data.isPlaced) {
      if (this.data.data[i].stock_qty > this.stockData[i].stock_qty) {
        qnt = this.stockData[i].stock_qty + 1;
      } else {
        qnt = this.stockData[i].stock_qty
      }

    } else {
      qnt = this.stockData[i].stock_qty + 1;
    }
    this.stockData[i].stock_qty = qnt
  }

  decrement(i: any) {
    if (this.stockData[i].stock_qty > 0) {
      this.stockData[i].stock_qty = this.stockData[i].stock_qty - 1;
    }
  }
  onInputChange(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;
    value = value.replace(/[^0-9]/g, '');
    input.value = value;
    this.stockData[index].stock_qty = Number(value);
  }

  fetchWealthyTradePrice(dontSetInitial = true) {

    let payload: any = {
      stock_price_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
      "subscriber_id": this.data.subscriber_id,
      "pouch_id": this.data.pouch_id,
      "mode": 1,
      "symbols": this.data?.data?.map((e: any) => `nse:${e.stock_code}-${e.stock_series}`)
    }


    if (this.data.from == 'smart-stocks') {
      payload.investment_amount = this.data.invesment_amount;
      delete payload.stock_price_date
      this.pouchService.fetch_stock_trade_price_wealthy_no_pouch(payload).subscribe((res: any) => {
        this.broker_balance = Number(res.broker_balance)
        this.current_trade_value = Number(res.current_investment)
        const stockprice = new Map();
        res.stock_trade_price.forEach((element: any) => {
          stockprice.set(element.stock_code, element)
        });

        (async () => {
          this.stockData = await Promise.all(this.stockData.map((e: any) => ({ ...e, close_price: stockprice.get(e.stock_code).close_price || 0 })))

          if (dontSetInitial) {
            this.initialCurrentTradeValue = Number(this.current_trade_value) + Number(this.currentTradeValue.transform(this.stockData))
          }
        })()
        this.show_ghost = false
      },()=> this.show_ghost = false)
    } else {
      this.pouchService.fetch_stock_trade_price_wealthy(payload).subscribe((res: any) => {
        this.broker_balance = Number(res.broker_balance)
        this.current_trade_value = Number(res.current_investment)
        const stockprice = new Map();
        res.stock_trade_price.forEach((element: any) => {
          stockprice.set(element.stock_code, element)
        });
        (async () => {
          this.stockData = await Promise.all(this.stockData.map((e: any) => ({ ...e, close_price: stockprice.get(e.stock_code).close_price || 0 })))

          if (dontSetInitial) {
            this.initialCurrentTradeValue = Number(this.current_trade_value) + Number(this.currentTradeValue.transform(this.stockData))
          }
        })()
        this.show_ghost = false
      },()=> this.show_ghost = false)

    }

  }
  fetchBajajTradePrice(dontSetInitial = true) {
    let payload: any = {
      stock_price_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
      "subscriber_id": this.data.subscriber_id,
      "pouch_id": this.data.pouch_id
    }
    if (this.data.from == 'smart-stocks') {
      payload.investment_amount = this.data.invesment_amount;
      delete payload.stock_price_date;
      let orders = this.stockData
      .map((e: any) => {
        return {
          stock_token: e?.stock_token,
          stock_code: e?.stock_code
        }
      })
    payload['stocks'] = orders;
      this.pouchService.fetch_stock_trade_price_bajaj_no_pouch(payload).subscribe((res: any) => {
        this.broker_balance = Number(res.broker_balance)
        this.current_trade_value = Number(res.current_investment)
        const stockprice = new Map();
        res.stock_trade_price.forEach((element: any) => {
          stockprice.set(element.stock_code, element)
        });

        (async () => {
          this.stockData = await Promise.all(this.stockData.map((e: any) => ({ ...e, close_price: stockprice.get(e.stock_code).close_price || 0 })))
          if (dontSetInitial) {
            this.initialCurrentTradeValue = Number(this.current_trade_value) + Number(this.currentTradeValue.transform(this.stockData))
          }
        })()
        this.show_ghost = false
      },()=> this.show_ghost = false)
    } else {
      let orders = this.stockData
        .map((e: any) => {
          return {
            stock_token: e?.stock_token,
            stock_code: e?.stock_code
          }
        })
      payload['stocks'] = orders;
      this.pouchService.fetch_stock_trade_price_bajaj(payload).subscribe((res: any) => {
        this.broker_balance = Number(res.broker_balance)
        this.current_trade_value = Number(res.current_investment)
        const stockprice = new Map();
        res.stock_trade_price.forEach((element: any) => {
          stockprice.set(element.stock_code, element)
        });
        (async () => {
          this.stockData = await Promise.all(this.stockData.map((e: any) => ({ ...e, close_price: stockprice.get(e.stock_code).close_price || 0 })))
          if (dontSetInitial) {
            this.initialCurrentTradeValue = Number(this.current_trade_value) + Number(this.currentTradeValue.transform(this.stockData))
          }
        })()
        this.show_ghost = false
      },()=> this.show_ghost = false)
    }

  }
  goToReviewOrder() {
    this.review_data = !this.review_data
    if (this.data.partner == 'wealthy') {
      this.fetchWealthyTradePrice(false)
    } else if (this.data.partner == 'bajajbroking') {
      this.fetchBajajTradePrice(false)
    }
  }
  closeAndOpenUpdateAmnt() {
    this.matDialogRef.close('openUpdate')
  }
  fetchLatestTrade() {
    if (this.data.partner == 'wealthy') {
      this.fetchWealthyTradePrice()
    } else if (this.data.partner == 'bajajbroking') {
      this.fetchBajajTradePrice()
    }
  }

}


