<ng-container *ngIf="link === 'landing'">
  <div class="public-wrapper landing_page_container">
    <div class="public">
      <div class="minH-90 white-bg choice-btn position-relative" style="display: flex;align-items: center;justify-content: space-between;padding: 10px;">
        <img style="width: 125px" src="../../../../../assets/images/logo/kandkfinserv.jpg" />
        <button style="width: 117px; height: 44px; border-radius: 35px" (click)="onLogin()">
          Login
        </button>
      </div>

      <div class="container-lg pt-60" style="position: relative; z-index: 5">
        <div class="f-80 fw-500 white w-47 text-style text-center">
          Be <b>SMART</b> when investing
        </div>

        <div class="white fw-400 f-24 text-center subText mt-20">
          <p>Eliminate emotional influences on financial decisions</p>
          <p>utilizing our SMART method</p>
          <p>
            <span class="f-green bold">S</span>ystematic,
            <span class="f-green bold">M</span>omentum, <span class="f-green bold">A</span>I,
            <span class="f-green bold">R</span>obust longterm trends,
            <span class="f-green bold">T</span>ailstocks
          </p>
        </div>
        <div class="reg-btn mt-70 pb-310">
          <button class="green-btn" (click)="clickOnRegister()">
            <div class="f-18 fw-600">
              Register now
              <img
                src="../../../../../assets/images/icons/next-arrow-blue.svg"
                style="padding-left: 15px" />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="public-slash-blue"></div>
    <div class="bg-image">
      <img src="../../../../../assets/images/icons/bg_pattern11.png" />
    </div>
    <div
      class="container-lg details"
      [ngClass]="publicPouchList?.length > 0 ? 'mb-initial' : 'mb-200'">
      <div class="details-header custom-width">
        <p class="heading-text f-black">Investing in the stock market isn't just for experts</p>
        <p class="sub-text f-black2">
          Smartvalues is an algorithm driven portfolio builder that can be used by ‘anyone and
          everyone’ to create and maintain portfolios based on their input criteria.
        </p>

        <p class="sub-text pt-20 f-black2">
          It is safer to invest in a 'group of stocks', or as we call them “Pouches”, which are
          grouped by attributes like large cap, or by a sector like pharmaceuticals, or by a theme
          like rural demand, than investing in individual stocks. We designed them smartly so you
          can grow your income without having to be an investment expert
        </p>
      </div>
    </div>
    <div class="container-lg products mt-50">
      <div class="pHeader text-blue custom-width" *ngIf="publicPouchList?.length > 0">
        OUR POUCHES
      </div>
      <div></div>

      <div class="pouches custom-width custom-width-change" style="margin-top: 30px">
        <div class="row justify-content-center" *ngIf="!isMobile">
          <div
            *ngFor="let element of publicPouchList?.slice(0, 4); let i = index"
            class="col-md-3 mb-20">
            <div #box (click)="navigateToPerformance(element.id)" class="colStyle">
              <div class="imgStyle">
                <img src="../../../../../assets/images/icons/pharma-majors.svg" />
              </div>
              <div class="heading">{{ element.name }}</div>
              <div class="description">{{ element.description }}</div>
              <div class="detail">
                <span class="text-grey invest fw-400 f-12">Min.Investment</span>
                <span class="text-blue amount fw-600 f-12"
                  >Rs. {{ getRound(element.min_amt) | number: '1.0-0' }}</span
                >
              </div>
              
            </div>
          </div>
        </div>

        <div class="row" *ngIf="isMobile">
          <div class="col-md-12 text-center testimonial-carousel f-black2">
            <ng-container *ngIf="publicPouchList?.length > 0">
              <ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="publicPouchList">
                <div *nguCarouselDef="let item" class="item">
                  <div class="tile">
                    <div>
                      <div class="col-md-4 mb-20">
                        <div class="colStyle" #box (click)="navigateToPerformance(item.id)">
                          <div class="imgStyle">
                            <img src="../../../../../assets/images/icons/pharma-majors.svg" />
                          </div>
                          <div class="heading">{{ item?.name }}</div>
                          <div class="description">{{ item?.description }}</div>
                          <div class="detail">
                            <span class="text-grey invest fw-400 f-12">Min.Investment</span>
                            <span class="text-blue amount fw-600 f-12"
                              >Rs. {{ getRound(item.min_amt) | number: '1.0-0' }}</span
                            >
                          </div>
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="myPoint mt-4 d-flex justify-content-center"
                  NguCarouselPoint
                  *ngIf="myCarousel">
                  <div
                    class="testimonial-dot-item mx-1"
                    *ngFor="let i of myCarousel.pointNumbers"
                    [class.active]="i === myCarousel.activePoint"
                    (click)="myCarousel.moveTo(i)"></div>
                </div>
              </ngu-carousel>
            </ng-container>
          </div>
        </div>

        <div class="row mt-05" *ngIf="publicPouchList?.length > 0">
          <p style="padding-left: 10px; margin-bottom: 0px !important; text-align: center">
            <strong>Disclaimer: </strong> "The securities quoted are for illustration only and are
            not recommendatory"
          </p>
        </div>

        <div class="row" style="margin-top: 30px">
          <div class="reg-btn pb-250" *ngIf="publicPouchList?.length > 0">
            <button class="white-btn">
              <div class="f-18 fw-600" (click)="viewPouchListAll()">
                View all pouches
                <img
                  src="../../../../../assets/images/icons/next-arrow-blue.svg"
                  style="padding-left: 15px" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="public-slash-green"></div>
    <div class="info-container">
      <div class="container-lg">
        <div class="info">
          <p class="heading-text f-black">How smart values can help you grow your income</p>
          <p class="sub-text mt-30 f-black2">
            Smartvalues is a SMART way to invest in stocks that is equally beneficial to beginners
            and experts.
          </p>
        </div>
      </div>
      <div class="d-flex align-item-baseline mt-30 pb-100 slider-container" *ngIf="!isMobile">
        <div class="col-md-8 slider-wrapper d-flex shiftLeft" [ngClass]="{ hideLeft: isLeftClick }">
          <div class="info1">
            <img src="../../../../../assets/images/icons/info1.png" />
            <p class="img-text" *ngIf="isLeftClick">I am an Expert</p>
          </div>
          <div class="slider" *ngIf="!isLeftClick">
            <app-slider [isLeft]="isLeftClick"></app-slider>
          </div>
          <div class="btn-pos" (click)="slide()" *ngIf="!isLeftClick">
            <img src="../../../../../assets/images/icons/blue_back.svg" />
          </div>
          <div class="btn-pos leftIcon" (click)="slide1()" *ngIf="isLeftClick">
            <img src="../../../../../assets/images/icons/blue_back.svg" />
          </div>
        </div>
        <div
          class="col-md-4 slider-wrapper2 d-flex hideRight"
          [ngClass]="{ shiftRight: isLeftClick }">
          <div class="slider2" *ngIf="isLeftClick">
            <app-slider [isLeft]="isLeftClick"></app-slider>
          </div>
          <div class="info2">
            <img src="../../../../../assets/images/icons/info2.png" />
            <p class="img-text" *ngIf="!isLeftClick">I am a novice</p>
          </div>
        </div>
      </div>

      <div class="d-flex align-item-baseline mt-30 pb-100 slider-container" *ngIf="isMobile">
        <div
          class="col-md-8 slider-wrapper-mob d-flex shiftLeft"
          [ngClass]="{ hideLeft: isLeftClick }">
          <div class="info1">
            <img src="../../../../../assets/images/icons/info1.png" />
            <p class="img-text" *ngIf="isLeftClick">I am an Expert</p>
          </div>
          <div class="slider" *ngIf="!isLeftClick">
            <app-slider [isLeft]="isLeftClick"></app-slider>
          </div>
          <div class="btn-pos" (click)="slide()" *ngIf="!isLeftClick">
            <img src="../../../../../assets/images/icons/blue_back.svg" />
          </div>
          <div class="btn-pos leftIcon" (click)="slide1()" *ngIf="isLeftClick">
            <img src="../../../../../assets/images/icons/blue_back.svg" />
          </div>
        </div>
        <div
          class="col-md-4 slider-wrapper2-mob d-flex hideRight"
          [ngClass]="{ shiftRight: isLeftClick }">
          <div class="slider2" *ngIf="isLeftClick">
            <app-slider [isLeft]="isLeftClick"></app-slider>
          </div>
          <div class="info2">
            <img src="../../../../../assets/images/icons/info2.png" />
            <p class="img-text" *ngIf="!isLeftClick">I am a novice</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-lg instruction-wrapper mt-50">
      <div class="custom-width mb-250">
        <div class="instruction">
          <p class="heading-text f-black">Its all about Momentum.</p>
          <p class="sub-text pt-20 f-black2">
            A momentum-based investing strategy is one in which securities that are rising are
            bought and those that are falling are sold.
          </p>

          <p class="sub-text pt-20 f-black2">
            It is a method that has proven profitable for over 200 years. The famous economist David
            Ricardo is said it have amassed a fortune using momentum strategies. A quarter century
            of academic evidence sits on top of these 200 years of empirical evidence. The 1993
            paper “Returns to Buying Winners and Selling Losers: Implications for Stock Market
            Efficiency” by Narasimhan Jegadeesh and Sheridan Titman found that strategies that buy
            well-performing stocks and sell poorly performing stocks generate significant positive
            returns.
          </p>

          <p class="sub-text pt-20 f-black2">
            Academic studies around the world have confirmed the findings in this paper. Lamron
            Analysts has been using momentum strategies in India successfully for over a decade.
            These strategies form the bedrock of the smartvalues algorithms.
          </p>
        </div>
      </div>
    </div>
    <div class="public-slash-blue2"></div>
    <div class="bg-image1">
      <img src="../../../../../assets/images/icons/bg_pattern2.png" />
    </div>
    <div class="footer-wrapper mt-50">
      <div class="container-lg custom-width">
        <div class="footer-container">
          <p class="heading-text white">Powered by Lamron Analysts</p>
          <p class="tagline white">SEBI Registration No. INA300003772</p>
          <p class="sub-text pt-20 light-white">
            Lamron Analysts specializes in research, development, and execution of trading systems.
          </p>
          <p class="sub-text pt-20 light-white">
            The firm was founded to offer investors a fresh perspective on managing their stock
            portfolios.
          </p>
          <p class="sub-text pt-20 light-white">
            We seek to generate significant long term compound growth for our shareholders and other
            investors. We incorporate the principles of trend following and momentum investing into
            our algorithms. Lamron Analysts is a SEBI Registered Investment Adviser.
          </p>
          <p class="sub-text pt-30 light-white">More from Lamron Analysts</p>
        </div>
        <div class="choice-btn mt-30 pb-70">
          <!-- <button class="white-btn m20">
              <div class="f-18 fw-600">
                Small case
              </div>
            </button> -->
          <button class="white-btn m20">
            <a href="https://lamronanalysts.com/smart-beta/" target="_blank">
              <div class="f-18 fw-600">SmartBeta</div></a
            >
          </button>
        </div>
        <div class="table-style custom_font_size">
          <div class="grve-column wpb_column grve-column-3-4">
            <div class="grve-column-wrapper">
              <div class="grve-row-inner grve-bookmark">
                <div class="grve-column-inner wpb_column grve-column-1">
                  <div class="grve-column-wrapper-inner">
                    <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                      <div class="wpb_wrapper">
                        <table>
                          <!-- <caption>
                              <strong style="padding-left: 10px;">Investment Adviser Details</strong>
                            </caption> -->
                          <tbody>
                            <tr class="addDisplay">
                              <td>
                                Registered Name:<strong>
                                  Lamron Analysts Pvt. Ltd. - Investment Adviser</strong
                                ><br />
                                SEBI Registration No:
                                <strong>INA300003772</strong>
                              </td>
                              <td rowspan="2">
                                Contact details of the Principle Officer:<br />
                                Name:<strong> Soumitra Sengupta</strong><br />
                                Contact no:<strong> 9831657426</strong><br />
                                Email id:<strong> soumitra.sengupta&#64;lamronanalysts.com</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>Type of Registration:<b>Non-Individual</b></td>
                            </tr>
                            <tr class="addDisplay">
                              <td>
                                Validity of Registration:
                                <strong>Until Cancelled</strong>
                              </td>
                              <td rowspan="2">
                                Corresponding SEBI regional/local office address: <br />
                                <strong
                                  >L&amp;T Chambers, 3rd Floor, 16 Camac Street, <br />Kolkata -
                                  700017</strong
                                >
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Address:
                                <strong>109/40B, Hazra Road, Kolkata - 700026</strong><br />

                                Telephone No. <strong>033-24740657</strong>
                              </td>
                            </tr>
                            <tr>
                              <td>CIN: <strong>U74140WB2000PTC091710</strong></td>
                            </tr>
                            <tr>
                              <td>BASL ID: <strong>1322</strong></td>
                            </tr>
                            <!--  -->
                            <tr class="addDisplay">
                              <td>
                                Compliance Officer Details:
                                <br />
                                Name:
                                <strong>Prabhas Chandra Ray</strong>
                                <br />
                                Contact:
                                <strong>7003362380</strong>
                                <br />
                                Email:
                                <strong>prabhas.ray&#64;lamronanalysts.com</strong>                          
                              </td>
                              <td rowspan="2">
                                Grievance Officer Details:<br />
                                Name:<strong> Prabhas Chandra Ray</strong><br />
                                Contact:<strong> 7003362380</strong><br />
                                Email:<strong> prabhas.ray&#64;lamronanalysts.com</strong>
                                
                              </td>
                            </tr>
                            
                            <tr class="addDisplay">
                              <td>
                                Customer Service:
                                <br />
                                Contact:
                                <strong>8100723140/ 9147380927</strong>
                                <br />
                                Email:
                                <strong>info&#64;lamronanalysts.com </strong> 
                              </td>
                              <td rowspan="2">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grve-row-inner grve-bookmark mt-20">
                <div class="grve-column-inner wpb_column grve-column-1">
                  <div class="grve-column-wrapper-inner">
                    <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                      <div class="wpb_wrapper">
                        <table style="width: 100%">
                          <caption>
                            <p style="padding-left: 10px">
                              <strong>IA Audit Status </strong>- Disclosure with repect to
                              compliance with Annual compliance audit requirement under Regulation
                              19(3) of SECURITIES AND EXCHANGE BOARD OF INDIA (INVESTMENT ADVISERS)
                              REDULATIONS, 2013 for last and current financial year are as under.
                            </p>
                          </caption>
                        </table>
                        <table>
                          <!-- <caption>
                              <p style="padding-left: 10px;"> <strong>IA Audit Status </strong>- Disclosure with repect to
                                compliance with Annual compliance audit requirement under Regulation 19(3) of SECURITIES
                                AND EXCHANGE BOARD OF INDIA (INVESTMENT ADVISERS) REDULATIONS, 2013 for last and current
                                financial year are as under.</p>
                            </caption> -->
                          <thead>
                            <tr>
                              <td>Sr No</td>
                              <td>Financial Year</td>
                              <td>Compliance Audit Status</td>
                              <td>Remarks. if any</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>01</td>
                              <td>FY 2021-22</td>
                              <td>Conducted</td>
                              <td>NIL</td>
                            </tr>
                            <tr>
                              <td>02</td>
                              <td>FY 2020-21</td>
                              <td>Conducted</td>
                              <td>NIL</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grve-row-inner grve-bookmark mt-20" style="padding-bottom: 50px">
                <div class="grve-column-inner wpb_column grve-column-1">
                  <div class="grve-column-wrapper-inner">
                    <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                      <div class="wpb_wrapper">
                        <!-- <table>
                            <caption>
                              <p style="padding-left: 10px;"> <strong>IA Audit Status </strong>- Disclosure with repect to
                                compliance with Annual compliance audit requirement under Regulation 19(3) of SECURITIES
                                AND EXCHANGE BOARD OF INDIA (INVESTMENT ADVISERS) REDULATIONS, 2013 for last and current
                                financial year are as under.</p>
                            </caption>
                            <thead>
                              <tr>
                                <td>Sr No</td>
                                <td>Financial Year</td>
                                <td>Compliance Audit Status</td>
                                <td>Remarks. if any</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>01</td>
                                <td>FY 2021-22</td>
                                <td>Conducted</td>
                                <td>NIL</td>
                              </tr>
                              <tr>
                                <td>02</td>
                                <td>FY 2020-21</td>
                                <td>Conducted</td>
                                <td>NIL</td>
                              </tr>

                            </tbody>
                          </table> -->
                        <table style="width: 100%">
                          <caption>
                            <p style="padding-left: 10px; margin-bottom: 0px !important">
                              <strong>Disclaimer: </strong> <br />
                              Investment in securities market are subject to market risks. Read all
                              the related documents carefully before investing.
                            </p>
                            <br />
                            <p style="padding-left: 10px">
                              Registration granted by SEBI, membership of BASL (in case of IAs) and
                              certification from NISM in no way guarantee performance of the
                              intermediary or provide any assurance of returns to investors
                            </p>
                          </caption>
                          <thead></thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="link === 'terms'">
  <div class="public-wrapper">
    <app-terms-and-policy></app-terms-and-policy>
  </div>
</ng-container>
<ng-container *ngIf="link === 'policy'">
  <div class="public-wrapper">
    <app-policy></app-policy>
  </div>
</ng-container>
