import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { forkJoin, fromEvent } from 'rxjs';

import { fork } from 'child_process';
import { cloneDeep } from 'lodash';
import { OnBoardingService } from 'src/app/modules/onbording/services/onboarding.service';
import { NotificationService } from 'src/app/services/notification.services';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { environment } from 'src/environments/environment';

import { SharedService } from './../../../../common/services/shared.service';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {
  @Input() sid: any;
  @Input() from_subTable: any;
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('panframe') panframe: ElementRef;
  @ViewChild('aadharframe') aadharframe: ElementRef;
  kycDocUpload = false;
  uploadFileProcess: any = {
    pan: {
      panFileName: '',
      showProgressForPan: false,
      progressValueForPan: 0,
      isPanUploaded: false
    },
    aadhar: {
      aadharFileName: '',
      showProgressForAadhar: false,
      progressValueForAadhar: 0,
      isAadharUploaded: false
    },
    aadhar_b: {
      aadhar_bFileName: '',
      showProgressForAadhar_b: false,
      progressValueForAadhar_b: 0,
      isAadhar_bUploaded: false
    }
  };

  kycDocs: any = {
    docs: [
      {
        doc_type: 'PAN',
        doc_id: '',
        filename: '',
        folderpath: ''
      },
      {
        doc_type: 'AADHAR',
        doc_id: '',
        filename: '',
        folderpath: ''
      },
      {
        doc_type: 'AADHAR_B',
        doc_id: '',
        filename: '',
        folderpath: ''
      }
    ]
  };
  reupload: any = {
    pan: false,
    aadhar: false,
    aadhar_b: false
  };
  pan: any = null;
  loaded = false;
  aadhar: any = null;
  aadhar_b: any = null;
  subId: any;
  showNodocs = false;
  constructor(
    public subService: SubsciberService,
    public sanitizer: DomSanitizer,
    public sharedService: SharedService,
    public notifyService: NotificationService,
    private OnBoardingService: OnBoardingService,
    private http: HttpClient
  ) {
    if (this.sid) {
      this.subId = this.sid;
    } else {
      this.subId = JSON.parse(localStorage.getItem('user') || '{}').id;
    }
  }
  userRole: any;
  intiate() {
    let user = JSON.parse(localStorage.getItem('user') || '{}');

    switch (user.attributes.role) {
      case 'admin':
        if (!this.sid) {
          break;
        }
        this.fetchDocs(this.sid);
        break;
      case 'subscriber':
        this.fetchDocs(user.id);
        break;
    }
    this.userRole = user.attributes.role;
  }
  FileSaved(fileSaved: boolean) {
    if (fileSaved) {
      this.showUpdate = true;
      this.pan = null;
      this.loaded = false;
      this.aadhar = null;
      this.aadhar_b = null;
      this.kycDocs = {
        docs: [
          {
            doc_type: 'PAN',
            doc_id: '',
            filename: '',
            folderpath: ''
          },
          {
            doc_type: 'AADHAR',
            doc_id: '',
            filename: '',
            folderpath: ''
          },
          {
            doc_type: 'AADHAR_B',
            doc_id: '',
            filename: '',
            folderpath: ''
          }
        ]
      };
      this.intiate();
    }
  }
  ngOnInit(): void {
    this.intiate();
  }
  fetchDocs(id: any) {
    let panBody: any = {
      doc_type: 'PAN',
      subscriber_id: id
    };
    let aadharBody: any = {
      doc_type: 'AADHAR',
      subscriber_id: id
    };
    let aadharBodyBack: any = {
      doc_type: 'AADHAR_B',
      subscriber_id: id
    };
    this.fetch(panBody, 'PAN');
    this.fetch(aadharBody, 'AADHAR');
    this.fetch(aadharBodyBack, 'AADHAR_B');
  }
  iframeLoading: any;
  fetch(body: any, type: any) {
   
    this.subService.fetchDocs(body).subscribe(
      (res: any) => {
        if (type == 'PAN') {
          this.pan = {
            created_date: res.created_date,
            filename: res.filename,
            file: this.generateReport(res.file, res) //this.sanitizer.bypassSecurityTrustResourceUrl(
            //   'data:application/pdf;base64,' + res.file
            // ),
          };
        }
        if (type == 'AADHAR') {
          this.aadhar = {
            created_date: res.created_date,
            filename: res.filename,
            file: this.generateReport(res.file, res) //this.sanitizer.bypassSecurityTrustResourceUrl(
            //  'data:application/pdf;base64,' + res.file
            //),
          };
        }
        if (type == 'AADHAR_B') {
          this.aadhar_b = {
            created_date: res.created_date,
            filename: res.filename,
            file: this.generateReport(res.file, res) //this.sanitizer.bypassSecurityTrustResourceUrl(
            //  'data:application/pdf;base64,' + res.file
            //),
          };
        }
      },
      (e) => {
        let user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user.attributes.role == 'admin') {
          this.showNodocs = true;
        } else {
          this.kycDocUpload = true;
          this.loaded = true;
        }
      },
      () => {
        this.loaded = true;
      }
    );
  }

  convertBase64toFile(base64Data: any, file_type: any): string {
    const byteArray = new Uint8Array(
      atob(base64Data)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], { type: file_type });
    const url = window.URL.createObjectURL(blob);
    return url;
  }
  private generateReport(report: any, response: any) {
    
    const reportUrl = this.convertBase64toFile(report, response.file_type);
    return this.sanitizer.bypassSecurityTrustResourceUrl(reportUrl);
  }
  reuploadfn(e: any) {
    if (e == 'pan') {
      this.reupload.pan = true;
    } else {
      this.reupload.aadhar = true;
    }
  }

  uploadFile(file: any, fileName: string, docType: string) {
    const form = new FormData();
    let username: any;
    let userInfo: any = JSON.parse(localStorage.getItem('user') || '{}');
    if (userInfo.attributes.role == 'subscriber') {
      username = userInfo.attributes.name.first + ' ' + userInfo.attributes.name.last;
    } else {
      //load from @input
    }

    if (docType === 'AADHAR') {
      form.append('files', file, fileName);
      form.append('doctype', `AADHAR:${fileName}`);
    }
    if (docType === 'AADHAR_B') {
      form.append('files', file, fileName);
      form.append('doctype', `AADHAR_B:${fileName}`);
    }
    if (docType === 'PAN') {
      form.append('files', file, fileName);
      form.append('doctype', `PAN:${fileName}`);
    }
    form.append('folderpath', username);

    this.http
      .post(environment.baseUrl_8082 + 'files/smartvalues/kyc_docs/upload', form, {
        reportProgress: true,
        observe: 'events'
      })
      .subscribe(
        (events: HttpEvent<any>) => {
          if (events.type === HttpEventType.UploadProgress) {
            if (events.total) {
              if (docType === 'AADHAR') {
                this.uploadFileProcess.aadhar.progressValueForAadhar = Math.round(
                  (events.loaded / events.total) * 100
                );
                this.uploadFileProcess.aadhar.isAadharUploaded = true;
              }
              if (docType === 'AADHAR_B') {
                this.uploadFileProcess.aadhar_b.progressValueForAadhar_b = Math.round(
                  (events.loaded / events.total) * 100
                );
                this.uploadFileProcess.aadhar_b.isAadhar_bUploaded = true;
              }
              if (docType === 'PAN') {
                this.uploadFileProcess.pan.progressValueForPan = Math.round(
                  (events.loaded / events.total) * 100
                );
                this.uploadFileProcess.pan.isPanUploaded = true;
              }
            }
          }
          if (events.type === HttpEventType.Response) {
            let KYC_docsArray: any = this.kycDocs['docs'];

            if (docType === 'PAN') {
              KYC_docsArray[0]['doc_id'] = events.body.files[fileName];
              KYC_docsArray[0]['filename'] = fileName;
              KYC_docsArray[0]['folderpath'] = username;
            }
            if (docType === 'AADHAR') {
              KYC_docsArray[1]['doc_id'] = events.body.files[fileName];
              KYC_docsArray[1]['filename'] = fileName;
              KYC_docsArray[1]['folderpath'] = username;
            }
            if (docType === 'AADHAR_B') {
              KYC_docsArray[2]['doc_id'] = events.body.files[fileName];
              KYC_docsArray[2]['filename'] = fileName;
              KYC_docsArray[2]['folderpath'] = username;
            }

            this.saveDoc(username, docType);
          }
        },
        (err: any) => {
          this.notifyService.showError('ERROR : File upload error', '');

          if (docType === 'AADHAR') {
            this.clearAdharFile({ target: { id: fileName } });
          } else if (docType === 'PAN') {
            this.clearPanFile({ target: { id: fileName } });
          } else if (docType === 'AADHAR_B') {
            this.clearAdhar_BFile({ target: { id: fileName } });
          }
        }
      );
  }

  saveDoc(name: any, doc: any) {
    let payload = cloneDeep(this.kycDocs);

    let KYC_docsArray: any = payload['docs'];

    KYC_docsArray.forEach((element: any, index: any) => {
      if (!element.doc_id) {
        KYC_docsArray.splice(index, 1);
      }
    });

    KYC_docsArray[0]['folderpath'] = name;
    this.OnBoardingService.saveKyc(payload).subscribe(
      (res: any) => {
        this.reupload[doc.toLowerCase()] = false;
        if (doc.toLowerCase() == 'pan') {
          this.uploadFileProcess[doc.toLowerCase()].showProgressForPan = false;
        } else if (doc.toLowerCase() == 'aadhar_b') {
          this.uploadFileProcess[doc.toLowerCase()].showProgressForAadhar_b = false;
        } else {
          this.uploadFileProcess[doc.toLowerCase()].showProgressForAadhar = false;
        }

        this.intiate();
      },
      (e) => {
        this.notifyService.showError('ERROR : Error saving docs', '');
      }
    );
  }

  onSelect(event: any) {
    if (event.source.id === 'AADHAR') {
      let file = event.addedFiles[0];
      this.uploadFileProcess.aadhar.progressValueForAadhar = 0;
      // for(let file of event.addedFiles){
      this.uploadFile(file, file.name, event.source.id);
      this.uploadFileProcess.aadhar.showProgressForAadhar = true;
      this.uploadFileProcess.aadhar.aadharFileName = file.name;

      // }
    }
    if (event.source.id === 'AADHAR_B') {
      let file = event.addedFiles[0];
      this.uploadFileProcess.aadhar_b.progressValueForAadhar_b = 0;
      // for(let file of event.addedFiles){
      this.uploadFile(file, file.name, event.source.id);
      this.uploadFileProcess.aadhar_b.showProgressForAadhar_b = true;
      this.uploadFileProcess.aadhar_b.aadhar_bFileName = file.name;

      // }
    }
    if (event.source.id === 'PAN') {
      let file = event.addedFiles[0];
      // for(let file of event.addedFiles){
      this.uploadFileProcess.pan.progressValueForPan = 0;
      this.uploadFile(file, file.name, event.source.id);
      this.uploadFileProcess.pan.showProgressForPan = true;
      this.uploadFileProcess.pan.panFileName = file.name;
    }

    // }
  }

  clearAdhar_BFile(event?: any) {
    let KYC_docsArray = this.kycDocs['docs'];
    let findDoc = this.kycDocs['docs'].find((doc: any) => doc.filename === event.target.id);
    KYC_docsArray[KYC_docsArray.indexOf(findDoc)] = {
      doc_type: 'AADHAR_B',
      doc_id: '',
      filename: '',
      folderpath: ''
    };
    this.uploadFileProcess.aadhar_b.showProgressForAadhar_b = false;
    this.uploadFileProcess.aadhar_b.isAadhar_bUploaded = false;
  }
  clearAdharFile(event?: any) {
    let KYC_docsArray = this.kycDocs['docs'];
    let findDoc = this.kycDocs['docs'].find((doc: any) => doc.filename === event.target.id);
    KYC_docsArray[KYC_docsArray.indexOf(findDoc)] = {
      doc_type: 'AADHAR',
      doc_id: '',
      filename: '',
      folderpath: ''
    };
    this.uploadFileProcess.aadhar.showProgressForAadhar = false;
    this.uploadFileProcess.aadhar.isAadharUploaded = false;
  }
  clearPanFile(event?: any) {
    let KYC_docsArray = this.kycDocs['docs'];
    let findDoc = this.kycDocs['docs'].find((doc: any) => doc.filename === event.target.id);
    KYC_docsArray[KYC_docsArray.indexOf(findDoc)] = {
      doc_type: 'PAN',
      doc_id: '',
      filename: '',
      folderpath: ''
    };

    this.uploadFileProcess.pan.showProgressForPan = false;
    this.uploadFileProcess.pan.isPanUploaded = false;
  }
  showUpdate: boolean = true;
  showUpdates() {
    this.showUpdate = false;
  }
}
