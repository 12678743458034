import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

import { PlansService } from 'src/app/modules/investment-pouches/services/plans.service';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { environment } from 'src/environments/environment';

import { AmountUpdatePopupData } from '../banner-subscription-details.component';
import {DeletePopupComponent} from "../../../../common/components/delete-popup/delete-popup.component";
import {NotificationService} from "../../../../services/notification.services";

interface updateAmountApiResponse {}

@Component({
  selector: 'app-amount-update-popup',
  templateUrl: './amount-update-popup.component.html',
  styleUrls: ['./amount-update-popup.component.scss']
})
export class AmountUpdatePopupComponent implements OnInit {
  updateAmount!: FormGroup;
  radioSelected = 'Top Up';
  showPay: boolean = true;
  investmentAmount: boolean = false;
  revisedSubscription: boolean = false;
  balanceSubscription: boolean = false;
  reviseSubsCriptionFee: number;
  revisedInverstmentAmount: number;
  initialInverstmentAmount: Number = 0;
  subscriptionAmountPaid: number;
  balanceSubscriptionAmountPaid: number;
  math = Math;
  buttonDisable: boolean = false;
  minInverstment: number;
  minInverstmentError: boolean = false;
  pouchId: any;
  getInvetmentAmount: any;
  showError: boolean = false;
  constructor(
    private route: ActivatedRoute,
    public pouchService: PouchService,
    private subscriberService: SubsciberService,
    private plansService: PlansService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: AmountUpdatePopupData,
    private dialogRef: MatDialogRef<AmountUpdatePopupComponent>,
    private matDialog:MatDialog,
    private  notification:NotificationService
  ) {
    this.pouchId = this.route.snapshot.paramMap.get('pouchId');

    this.pouchService
      .get_pouch_subscriber_status({
        pouch_id: this.pouchId,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id
      })
      .subscribe({
        next: (res: any) => {

          this.getInvetmentAmount = res[1].subscription_status[0].investment_amount;
        }
      });
  }

  ngOnInit(): void {
    this.minInverstment = this.dialogdata.min_investment;
    if (this.dialogdata.pouchStatus) {
      this.radioSelected = 'Sell Down';
    } else {
      this.radioSelected = 'Top Up';
    }
    this.createForm();
  }
  createForm() {
    this.updateAmount = new FormGroup({
      Amount: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)])
    });
  }
  radioChange(selectedValue: string) {
    this.showPay = true;
    if (selectedValue === 'Sell Down') {
      this.revisedSubscription = false;
      this.investmentAmount = false;
    } else {
      this.revisedSubscription = false;
      this.investmentAmount = false;
    }
  }
  onSubmit() {
    this.revisedInverstmentAmount =
      Number(this.dialogdata.subscriberInvestmentAmount) +
      (this.radioSelected === 'Top Up'
        ? Number(this.updateAmount.controls.Amount.value)
        : -Number(this.updateAmount.controls.Amount.value));
    if (this.updateAmount.controls.Amount.value <= this.dialogdata.subscriberInvestmentAmount) {
      this.showError = false;
      this.showPay = false;
    } else {
      this.showError = true;
      this.showPay = true;
    }
    this.buttonDisable = true;

    if (!this.updateAmount.valid) {
      this.buttonDisable = false;
      this.updateAmount.markAllAsTouched();
      return;
    } else {
      if (this.updateAmount.controls.Amount.value < Math.round(this.minInverstment)) {
        this.minInverstmentError = true;
      }
      this.revisedInverstmentAmount =
        Number(this.dialogdata.subscriberInvestmentAmount) +
        (this.radioSelected === 'Top Up'
          ? Number(this.updateAmount.controls.Amount.value)
          : -Number(this.updateAmount.controls.Amount.value));
      if (this.radioSelected === 'Top Up') {
        this.fetchSubscriptionFee();
      } else {
        this.revisedSubscription = false;
        this.investmentAmount = true;
        // this.showPay = false;
        this.buttonDisable = false;
      }
    }
  }
  revisedAmount() {
    this.investmentAmount = true;
  }
  fetchSubscriptionFee() {
    const { pouchId, pouchFrequency, subscriberID }: AmountUpdatePopupData = this.dialogdata;
    let payload = {
      pouch_id: pouchId,
      subscription_freq: pouchFrequency,
      subscriber_id: subscriberID,
      change_amount: this.updateAmount.controls.Amount.value
    };

    this.subscriberService.postUpdateAmount(payload).subscribe({
      next: (res: any) => {
        this.reviseSubsCriptionFee = res.new_subscription_amount;
        this.subscriptionAmountPaid = res.old_subscription_amount;
        this.balanceSubscriptionAmountPaid = res.subscription_amount_payable;
        this.revisedSubscription = true;
        this.investmentAmount = true;
        if (!res.subscription_amount_payable) {
          this.showPay = false;
        } else {
           this.showPay = true;
        }
        this.buttonDisable = false;
      }
    });
  }
  goToPayment(subscriptionAmount: number) {
    const data = {
      subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id
    };
    this.plansService.getBillingDetails(data).subscribe((res: any) => {

      let billings = res[0]?.sub_bill_addrs[0];
      delete billings.rowIndex;

      const {
        pouchId,
        pouchFrequency,
        subscriberID,
        subscriberInvestmentAmount
      }: AmountUpdatePopupData = this.dialogdata;

      let payload = {
        investment_amount: String(subscriberInvestmentAmount),
        change_amount: String(this.updateAmount.controls.Amount.value),
        pouch_id: String(pouchId),
        frequency: pouchFrequency,
        subscription_amount: String(subscriptionAmount),
        subscriber_id: subscriberID,
        redirect_url: `${window.location.host}/investment/map-subscriber/${pouchId}`
      };
      this.subscriberService.intiatePaymentGatewaysvfTopUp(payload).subscribe({
        next: (res: any) => {
          const { access_code, encRequest } = res;
          const form = document.createElement('form');
          form.setAttribute('action', environment.paymentGatewayUrl);
          form.setAttribute('method', 'post');
          const input = document.createElement('input');
          input.setAttribute('name', 'encRequest');
          input.setAttribute('value', encRequest);
          form.appendChild(input);

          const merchantIdInput = document.createElement('input');
          merchantIdInput.setAttribute('name', 'access_code');
          merchantIdInput.setAttribute('value', access_code);
          form.appendChild(merchantIdInput);
          document.body.appendChild(form);
          sessionStorage.setItem(
            'pouch',
            JSON.stringify({
              data: {
                isRenew: 'updateInvestment',
                investment_amount: String(this.revisedInverstmentAmount),
                pouch_id: payload.pouch_id,
                change_amount: payload.change_amount
              }
            })
          );
          sessionStorage.setItem(
            'billings',
            JSON.stringify({
              ...billings
            })
          );
          form.submit();
        }
      });
    });
  }
  saveAmount(sellDown: string) {
    const {
      pouchId,
      pouchFrequency,
      subscriberID,
      subscriberInvestmentAmount
    }: AmountUpdatePopupData = this.dialogdata;
    let sellDownData: any = {
      sellDownValue: sellDown,
      data: {
        isRenew: 'updateInvestment',
        investment_amount: String(this.revisedInverstmentAmount),
        pouch_id: pouchId,
        change_amount:
          sellDown == 'Sell Down'
            ? -this.updateAmount.controls.Amount.value
            : this.updateAmount.controls.Amount.value
      }
    };
    this.dialogRef.close(sellDownData);
  }
  onInputChange(event: any) {
    const enteredValue = event.target.value;
    if (enteredValue || enteredValue === '') {
      this.showPay = true;
      this.investmentAmount = false;
      this.revisedSubscription = false;
      this.minInverstmentError = false;
    }
  }
  liquidate(){
   const dialog= this.matDialog.open(DeletePopupComponent,{
    data:{
      message:'Please confirm whether you want to liquidate the pouch.',
      title:'',
      type:'liquidate'
    }
  }).afterClosed().subscribe((res)=>{
    if(res.type=='yes'){

      let body={
        "subscriber_id":localStorage.getItem('id'),
        "pouch_id":this.dialogdata.pouchId
      }
      this.pouchService.liquidatePouch(body).subscribe({
        next: (res: any) => {
          this.dialogRef.close('liquidate');
          this.notification.showSuccess(`Liquidate pouch ${this.dialogdata.pouchName} sucessfully`,'')
        },
        error:(e)=>{
          this.notification.showError(e.error.error,'')
        }
      })
    }
    })
  }
}
