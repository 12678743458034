import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PublicService } from 'src/app/modules/public/services/public.service';
import * as LoginActions from '../../modules/auth/store/actions/auth.action';
import { PublicFacadeService } from 'src/app/modules/public/state/public.facade.services';
@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private router: ActivatedRoute, private store: Store, private route: Router, private authService: AuthService, private publicService: PublicService ,private publicFacadeService:PublicFacadeService) {
    localStorage.removeItem('user')
    localStorage.removeItem('subscriptionPouchdetails')
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('stock_price_uploaded_date')
    this.publicFacadeService.setUploadDateToStore();
  }
  ngOnInit() {
    this.router.params.subscribe((res) => {


      this.router.queryParams.subscribe((params) => {
        const code = params['code'];
        const host = window.location.host;
        const subdomain = host.split('.')[0];
        const isWealthyDomain = subdomain === 'wealthy';



        if (isWealthyDomain && code === 'error') {
          this.openSSO();
        } else if (isWealthyDomain && code !== 'error') {

          this.authService.getLoginTokens({ code: code }).subscribe((response:any) => {

            this.authService.fetchUser(response.access_token, response.id_token).subscribe(
              (userres) => {
                this.authService.setTokens(response);
                localStorage.setItem('refreshToken', response.refresh_token);
                localStorage.setItem('user', JSON.stringify(userres));
                this.authService.setUser(userres, `/investment/map-subscriber/${res.id}`);
                this.route.navigateByUrl(`/investment/map-subscriber/${res.id}`)
              },
            )
          }, (e) => {
            console.error(e);
            this.openSSO();
          })

        } else if (!isWealthyDomain) {
          this.route.navigate(['/auth/login']);
        }
      });
    });
  }
  openSSO() {
    this.publicService.getWealthySsoUrl().subscribe(({ url }: any) => {
      window.location.href = url;
    });
  }

}
