<ng-container *ngIf="parent !== 'createPouch'">
  <div class="pouch-composit-container">
    <div class="row align-items-end" *ngIf="!isHowCreated && !isReviewHistory">
      <div class="col-md-3 mt-20" *ngIf="radioSelected === 0 && userType !== 'subscriber'">
        <div>
          <p class="f-12 text-grey mb-05">Select Subscriber</p>
        </div>

        <mat-form-field appearance="outline" class="w-25 w-100">
          <mat-select [(ngModel)]="selectedSubscriber" (selectionChange)="fetchIndiviualSub()">
            <mat-option [value]="item?.subscriber_id" *ngFor="let item of subList">{{ item?.subscriber_first_name }}
              {{ item?.subscriber_last_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-9 mt-20">
        <div class="row" style="row-gap: 10px;">
          <div class="col-sm-4 col-12 col_custom_style" *ngIf="radioSelected == 0">
            <div class="col-md-12">Current Investment</div>
            <div class="col-md-12" *ngIf="currentInvestment">
              Rs.
              {{ currentInvestment | currency: 'INR' : '' : '1.2-2' : 'en-IN' || '-' }}
            </div>
            <div class="col-md-12" *ngIf="!currentInvestment">-</div>
          </div>
          <div class="col-sm-4 col-12 col_custom_style">
            <div class="col-md-12">{{radioSelected==0?'AUA':'Total Returns'}}</div>
            <div class="col-md-12">
              <span>
                <span *ngIf="!totalReturns">-</span>
                <span *ngIf="totalReturns">Rs.</span>
                <span *ngIf="totalReturns > 0" class="text-right">
                  {{ totalReturns | currency: 'INR' : '' : '1.2-2' : 'en-IN' || '-' }}
                </span>
                <span *ngIf="totalReturns == 0" class="text-right">
                  {{ '-' }}
                </span>
                <span *ngIf="totalReturns < 0" class=" text-right">
                  <!-- <span *ngIf="totalReturns < 0" class="error text-right"> -->
                  {{'-'}}
                  <!-- ({{ myMath.abs(totalReturns) | currency: 'INR' : '' : '1.2-2' : 'en-IN' || '-' }}) -->
                </span>
              </span>
            </div>
          </div>
          <div class="col-sm-4 col-12 col_custom_style">
            <div class="col-md-12">Total Returns %</div>
            <div class="col-md-12">
              <span *ngIf="fundReturns === 'NaN'">-</span>
              <span class="" *ngIf="fundReturns > 0"> {{ fundReturns || 0.0 }} %</span>
              <span class="pt-10" *ngIf="fundReturns == 0"> {{ 0.0 }} % </span>
              <span class="pt-10" *ngIf="fundReturns < 0">
                <span style="color: red">({{ transformtValue(fundReturns) || 0.0 }}) %</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-10">
      <ng-container *ngIf="radioSelected === 0">
        <mat-radio-group *ngIf="userType === 'admin' || userType === 'partner'" [(ngModel)]="radioSelectedSubscriber"
          aria-label="Select an option" (change)="valueChangeSubscriber($event)">
          <mat-radio-button [value]="0">
            <span class="fw-400 f-14">Active</span>
          </mat-radio-button>
          <mat-radio-button class="px-3" [value]="1"><span class="fw-400 f-14">Inactive</span></mat-radio-button>
        </mat-radio-group>
      </ng-container>
    </div>
    <div class="showDiv">
      <div class="table-con" *ngIf="radioSelected === 0">
        <div *ngIf="partnerList.includes(domainName)" class="d-flex align-items-center justify-content-end"
          style="column-gap: 10px;">
          check status : <img class="pointer" (click)="checkStatus()" matTooltip="Check Status"
            src="../../../../assets/images/icons/bi_clipboard-check.svg" style="width: 18px; height: 18px;" />
        </div>
    
        <div class="is_mobile_view">
          <div class="mt-10">
            <div class="performance_container">
              <div>
                <div class="f-14 fw-600">Pending Transactions</div>
              </div>
            </div>
            <ng-container *ngFor="let element of pendingStocks?.filteredData">
              <div class="more_action">
                <div class="row stock_container">
                  <div class="col-4">
                    <div class="f-14 fw-500 collapse_word">{{element.stock_name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Quantity</div>
                    <div class="f-14 fw-400 collapse_word"> {{ myMath.abs(element.stock_qty || 0) }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Proposed Date</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.proposed_date | date: 'dd MMM yyy' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Transaction Type</div>
                    <div class="f-14 fw-400 collapse_word" >{{ element.txn_type }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Transaction Status</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.txn_status }}</div>
                  </div>

                </div>
                <div style="display: flex;align-items: center;">
                  <span style="display: flex">
                    <ng-container *ngIf="!partnerList.includes(domainName)">
                      <img class="pointer" matTooltip="Edit" src="../../../../assets/images/icons/ep_edit_blue.svg"
                        style="width: 18px; height: 18px; margin-left: 20px" (click)="updateTransaction(element)" />
                    </ng-container>
                    <ng-container *ngIf="partnerList.includes(domainName)">
                      <img class="pointer" *ngIf="element?.txn_status !== 'PLACED'" matTooltip="Edit"
                        src="../../../../assets/images/icons/ep_edit_blue.svg"
                        style="width: 18px; height: 18px; margin-left: 20px" (click)="updateTransaction(element)" />
                    </ng-container>
                    <img *ngIf="element?.txn_status == 'PLACED' && !partnerList.includes(domainName)" class="pointer"
                      matTooltip="Check Status" src="../../../../assets/images/icons/bi_clipboard-check.svg"
                      style="width: 18px; height: 18px; margin-left: 20px" />
                  </span>
                </div>
              </div>
              <div class="divider_style"></div>
            </ng-container>
            <ng-container *ngIf="pendingStocks?.filteredData?.length === 0">
              <div class="grey_color">No Records Found.</div>
            </ng-container>

          </div>
       
        </div> 
        <table mat-table [dataSource]="pendingStocks" class="mt-25 is_web_view">
          <ng-container matColumnDef="title">
            <th class="f-14 heading-1 headingStyle" mat-header-cell *matHeaderCellDef [attr.colspan]="9">
              <div>Pending Transactions</div>
            </th>
          </ng-container>

          <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sr. No.</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stock_name">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style="text-align: left">
              Stocks
            </th>
            <td mat-cell *matCellDef="let element" class="stock-td">
              {{ element.stock_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Quantity
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ myMath.abs(element.stock_qty || 0) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Proposed_Date">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Proposed Date
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.proposed_date | date: 'dd MMM yyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="transaction_type">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="3">Transaction Type</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.txn_type }}
            </td>
          </ng-container>
          <ng-container matColumnDef="transaction_status">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="3">Transaction Status</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.txn_status }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Action
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" style="width: 150px">
              <span style="display: flex">
                <ng-container *ngIf="!partnerList.includes(domainName)">
                  <img class="pointer" matTooltip="Edit" src="../../../../assets/images/icons/ep_edit_blue.svg"
                    style="width: 18px; height: 18px; margin-left: 20px" (click)="updateTransaction(element)" />
                </ng-container>
                <ng-container *ngIf="partnerList.includes(domainName)">
                  <img class="pointer" *ngIf="element?.txn_status !== 'PLACED'" matTooltip="Edit"
                    src="../../../../assets/images/icons/ep_edit_blue.svg"
                    style="width: 18px; height: 18px; margin-left: 20px" (click)="updateTransaction(element)" />
                </ng-container>
                <img *ngIf="element?.txn_status == 'PLACED' && !partnerList.includes(domainName)" class="pointer"
                  matTooltip="Check Status" src="../../../../assets/images/icons/bi_clipboard-check.svg"
                  style="width: 18px; height: 18px; margin-left: 20px" />
              </span>
            </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns3.length">
              No Pending Transaction Found.
            </td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="['title']"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
          <tr class="f-14" mat-row *matRowDef="let row; columns: displayedColumns3"></tr>
        </table>
      </div>
      <div class="table-con">        
        <div class="is_mobile_view">
          <div class="mt-10">
            <div class="performance_container" style="column-gap: 50px;">
              <div>
                <div class="f-14 fw-600">Stocks advised to Hold </div>
                <div class="f-12 fw-600 grey_color">(Current Pouch Composition)</div>
              </div>
              <div>
                <div class="f-14 fw-400" style="text-align: end;">Total Unrealized Profit / Loss %	</div>
                <div class="f-14 fw-600 text-end">
                  <span *ngIf="totalHoldPL > 0" class="text-right">
                    <img src="../../../../assets/images/stock_icons/green_positive.svg">{{ totalHoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
                  </span>
                  <span *ngIf="totalHoldPL < 0" class="error text-right">
                    <img src="../../../../assets/images/stock_icons/red_negative.svg"> ({{ myMath.abs(totalHoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
                  </span>
                </div>
              </div>
            </div>
            
            <ng-container *ngFor="let element of holdStocks">
              <div class="more_action">
                <div class="row stock_container">
                  <div class="col-4">
                    <div class="f-14 fw-500 collapse_word">{{element.stock_name}}</div>
                  </div>
                  <div class="col-4" *ngIf="radioSelected == 0">
                    <div class="f-12 fw-400 grey_color collapse_word">Quantity</div>
                    <div class="f-14 fw-400 collapse_word"> {{ myMath.abs(element.stock_qty || 0) }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Date</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.buy_date | date: 'mediumDate' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Price</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Current Value Date</div>
                    <div class="f-14 fw-400 collapse_word">              <ng-container *ngIf="radioSelected == 0">
                      {{ element.fact_date | date: 'mediumDate' }}
                    </ng-container>
                    <ng-container *ngIf="radioSelected != 0">
                      {{ (element?.sp_date | date: 'mediumDate') || '' }}
                    </ng-container></div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Current Value Price</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.close_price | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Current Value Wt.%</div>
                    <div class="f-14 fw-400 collapse_word">{{ element?.current_wt }}</div>
                  </div>
                  <div class="col-4"  *ngIf="radioSelected == 0">
                    <div class="f-12 fw-400 grey_color collapse_word">Profit / Loss Amount</div>
                    <div class="f-14 fw-400 collapse_word">              <span *ngIf="element.unrealized_returns > 0">{{
                      element.unrealized_returns | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                      }}</span>
                    <span class="error" *ngIf="element.unrealized_returns < 0">
                      ({{
                      transformtPercentValue(element.unrealized_returns)
                      | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                      }})</span></div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Profit / Loss %</div>
                    <div class="f-14 fw-400 collapse_word">              <span *ngIf="element.price_change_unrealized > 0">{{
                      element.price_change_unrealized
                      }}</span>
                    <span class="error" *ngIf="element.price_change_unrealized < 0">
                      ({{ transformtPercentValue(element.price_change_unrealized) }})</span></div>
                  </div>
                </div>
                <div style="display: flex;align-items: center;">
                  <img class="pointer" *ngIf="element['partial_sale'] == 'N'" matTooltip="Delete"
                  src="../../../../assets/images/icons/delete_blue.svg" (click)="deleteStock(element, 'hold')"
                  style="width: 18px; height: 18px" />
                </div>
              </div>
              <div class="divider_style"></div>
            </ng-container>
            <ng-container *ngIf="holdStocks?.length === 0">
              <div class="grey_color">No Records Found.</div>
            </ng-container>
         
          </div>
       
        </div> 
        <table mat-table [dataSource]="holdStocks" class="mt-25 is_web_view">
          <div style="display: none">{{ HoldStock(holdStocks.length) }}</div>
          <ng-container matColumnDef="buy_date">
            <th class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.buy_date | date: 'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="buy_rate">
            <th class="price-td" mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_date">
            <th class="price-td" class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="radioSelected == 0">
                {{ element.fact_date | date: 'mediumDate' }}
              </ng-container>
              <ng-container *ngIf="radioSelected != 0">
                {{ (element?.sp_date | date: 'mediumDate') || '' }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_rate">
            <th mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.close_price | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cr_wt">
            <th mat-header-cell *matHeaderCellDef>Wt.%</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element?.current_wt }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sr. No.</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stock_name">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style="text-align: left">
              Stocks
            </th>
            <td mat-cell *matCellDef="let element" class="stock-td">
              {{ element.stock_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stock_wt">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              {{ radioSelected == 0 ? 'Quantity' : 'Wt.%' }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ radioSelected == 0 ? element.stock_qty : element.stock_wt }}
            </td>
          </ng-container>
          <ng-container matColumnDef="header-row-third-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Purchase
            </th>
          </ng-container>
          <ng-container matColumnDef="header-row-forth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              Current Value*
            </th>
          </ng-container>
          <ng-container matColumnDef="header-row-fifth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Profit / Loss
            </th>
          </ng-container>

          <ng-container matColumnDef="price_change_realized">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Profit / Loss %
            </th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_unrealized > 0">{{
                element.price_change_unrealized
                }}</span>
              <span class="error" *ngIf="element.price_change_unrealized < 0">
                ({{ transformtPercentValue(element.price_change_unrealized) }})</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="profit-loss_amnt">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.unrealized_returns > 0">{{
                element.unrealized_returns | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }}</span>
              <span class="error" *ngIf="element.unrealized_returns < 0">
                ({{
                transformtPercentValue(element.unrealized_returns)
                | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="profit-loss_perc">
            <th mat-header-cell *matHeaderCellDef>%</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_unrealized > 0">{{
                element.price_change_unrealized
                }}</span>
              <span class="error" *ngIf="element.price_change_unrealized < 0">
                ({{ transformtPercentValue(element.price_change_unrealized) }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Action</th>
            <td class="text-center" mat-cell *matCellDef="let element">
              <img class="pointer" *ngIf="element['partial_sale'] == 'N'" matTooltip="Delete"
                src="../../../../assets/images/icons/delete_blue.svg" (click)="deleteStock(element, 'hold')"
                style="width: 18px; height: 18px" />
            </td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th class="f-14 heading-2 headingStyle" mat-header-cell *matHeaderCellDef [attr.colspan]="12">
              Stocks advised to Holds ( Current Pouch Composition)
            </th>
          </ng-container>

          <ng-container matColumnDef="total">
            <td mat-footer-cell *matFooterCellDef colspan="8" style="text-align: right">
              {{ radioSelected == 0 ? 'Total' : 'Total Unrealized Profit / Loss %' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="total1">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalHoldPLAmnt > 0" class="text-right">
                {{ totalHoldPLAmnt | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalHoldPLAmnt < 0" class="error text-right">
                ({{ myMath.abs(totalHoldPLAmnt) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="total2">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalHoldPL > 0" class="text-right">
                {{ totalHoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalHoldPL < 0" class="error text-right">
                ({{ myMath.abs(totalHoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['title']"></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'header-row-fifth-group',
                    'action'
                  ]
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'price_change_realized'
                  ]
            "></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? displayedColumns
                : ['buy_date', 'buy_rate', 'sell_date', 'sell_rate', 'cr_wt']
            "></tr>
          <tr class="f-14" mat-row *matRowDef="
              let row;
              columns: radioSelected == 0
                ? displayedColumns1
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'buy_date',
                    'buy_rate',
                    'sell_date',
                    'sell_rate',
                    'cr_wt',
                    'price_change_realized'
                  ]
            "></tr>
          <tr mat-footer-row *matFooterRowDef="
              radioSelected == 0 ? ['total', 'total1', 'total2'] : ['total', 'total2']
            "></tr>
        </table>
      </div>

      <div class="table-con">
       
        <div class="is_mobile_view">
          <div class="mt-10">
            <div class="performance_container" style="column-gap: 50px;">
              <div>
                <div class="f-14 fw-600">Stocks advised to Sell</div>
              </div>
              <div>
                <div class="f-14 fw-400" style="text-align: end;">Total Realized Profit / Loss %</div>
                <div class="f-14 fw-600 text-end">
                  <span *ngIf="totalSoldPL > 0" class="text-right">
                    <img src="../../../../assets/images/stock_icons/green_positive.svg"> {{ totalSoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
                  </span>
                  <span *ngIf="totalSoldPL < 0" class="error text-right">
                    <img src="../../../../assets/images/stock_icons/red_negative.svg"> ({{ myMath.abs(totalSoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
                  </span>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let element of sellStocks">
              <div class="more_action">
                <div class="row stock_container">
                  <div class="col-4">
                    <div class="f-14 fw-500 collapse_word">{{element.stock_name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word"> {{ radioSelected == 0 ? 'Quantity' : 'Weightage %' }}</div>
                    <div class="f-14 fw-400 collapse_word">              {{
                      radioSelected == 0
                      ? myMath.abs(element.stock_qty)
                      : transformtValue(element.stock_wt)
                      }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Date</div>
                    <div class="f-14 fw-400 collapse_word">{{ element?.buy_date | date: 'mediumDate' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Price</div>
                    <div class="f-14 fw-400 collapse_word"> {{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Sale Date</div>
                    <div class="f-14 fw-400 collapse_word"> {{ element?.sell_date | date: 'mediumDate' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Sale Price</div>
                    <div class="f-14 fw-400 collapse_word"> {{ element?.sell_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Profit / Loss</div>
                    <div class="f-14 fw-400 collapse_word">              <span *ngIf="element.price_change_realized > 0">{{
                      element.price_change_realized
                      }}</span>
                    <span class="error" *ngIf="element.price_change_realized < 0">
                      ({{ transformtPercentValue(element.price_change_realized) }})</span></div>
                  </div>
                </div>

              </div>
              <div class="divider_style"></div>
            </ng-container>
            <ng-container *ngIf="sellStocks?.length === 0">
              <div class="grey_color">No Records Found.</div>
            </ng-container>
             
          </div>
       
        </div> 
        <table mat-table [dataSource]="sellStocks" class="mat-elevation-z8 is_web_view">
          <ng-container matColumnDef="buy_date">
            <th class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.buy_date | date: 'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="buy_rate">
            <th class="price-td" mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_date">
            <th class="price-td" class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.sell_date | date: 'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_rate">
            <th mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element?.sell_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sr. No.</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stock_name">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style="text-align: left">
              Stocks
            </th>
            <td mat-cell *matCellDef="let element" class="stock-td">
              {{ element.stock_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stock_wt">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              {{ radioSelected == 0 ? 'Quantity' : 'Weightage %' }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{
              radioSelected == 0
              ? myMath.abs(element.stock_qty)
              : transformtValue(element.stock_wt)
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="header-row-third-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Purchase
            </th>
          </ng-container>
          <ng-container matColumnDef="header-row-forth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Sale
            </th>
          </ng-container>
          <ng-container matColumnDef="price_change_realized">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Profit / Loss %
            </th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_realized > 0">{{
                element.price_change_realized
                }}</span>
              <span class="error" *ngIf="element.price_change_realized < 0">
                ({{ transformtPercentValue(element.price_change_realized) }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="header-row-fifth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Profit / Loss
            </th>
          </ng-container>
          <ng-container matColumnDef="title">
            <th class="f-14 heading-1 headingStyle" mat-header-cell *matHeaderCellDef [attr.colspan]="10">
              Stocks advised to Sell
            </th>
          </ng-container>
          <ng-container matColumnDef="total">
            <td mat-footer-cell *matFooterCellDef colspan="7" style="text-align: right">
              {{ radioSelected == 0 ? 'Total' : 'Total Realized Profit / Loss %' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="total2">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalSoldPL > 0" class="text-right">
                {{ totalSoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalSoldPL < 0" class="error text-right">
                ({{ myMath.abs(totalSoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="total1">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalSoldPLAmnt > 0" class="text-right">
                {{ totalSoldPLAmnt | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalSoldPLAmnt < 0" class="error text-right">
                ({{ myMath.abs(totalSoldPLAmnt) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="profit-loss_amnt">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.realized_returns > 0">{{
                element.realized_returns | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }}</span>
              <span class="error" *ngIf="element.realized_returns < 0">
                ({{
                transformtPercentValue(element.realized_returns)
                | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="profit-loss_perc">
            <th mat-header-cell *matHeaderCellDef>%</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_realized > 0">{{
                element.price_change_realized
                }}</span>
              <span class="error" *ngIf="element.price_change_realized < 0">
                ({{
                transformtPercentValue(element.price_change_realized)
                | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Action</th>
            <td class="text-center" mat-cell *matCellDef="let element">
              <img class="pointer" matTooltip="Delete" src="../../../../assets/images/icons/delete_blue.svg"
                *ngIf="element?.rk == 1" (click)="deleteStock(element, 'sell')" style="width: 18px; height: 18px" />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['title']"></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'header-row-fifth-group',
                    'action'
                  ]
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'header-row-fifth-group'
                  ]
            "></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? displayedColumns_old
                : ['buy_date', 'buy_rate', 'sell_date', 'sell_rate']
            "></tr>
          <tr class="f-14" mat-row *matRowDef="
              let row;
              columns: radioSelected == 0
                ? displayedColumns1_old
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'buy_date',
                    'buy_rate',
                    'sell_date',
                    'sell_rate',

                    'price_change_realized'
                  ]
            "></tr>
          <tr mat-footer-row *matFooterRowDef="
              radioSelected == 0 ? ['total', 'total1', 'total2'] : ['total', 'total2']
            "></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="parent && pouchId">
    <div class="col-2 text-end">
      <button class="theme-btn primary-btn" type="button" (click)="onNextClick($event)">
        Next
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="parent == 'createPouch'">
  <div class="pouch-composit-container">
    <div class="mt-20" *ngIf="!isHowCreated && !isReviewHistory">
      <div class="d-flex align-items-center">
        <div class="ml-10 mt-10">
          Return on funds deployed as on {{ since | date: 'dd MMM yyyy' }} :
          <span class="" *ngIf="fundReturns > 0"> {{ fundReturns || 0.0 }} % </span>
        </div>

        <span class="pt-10" *ngIf="fundReturns == 0"> {{ 0.0 }} % </span>
        <span class="pt-10" *ngIf="fundReturns < 0">
          <span>({{ transformtValue(fundReturns) || 0.0 }}) %</span>
        </span>
      </div>
    </div>

    <div class="showDiv">
      <div class="table-con" *ngIf="radioSelected === 0">
        
        <div class="is_mobile_view">
          <div class="mt-10">
            <div class="performance_container">
              <div>
                <div class="f-14 fw-600">Pending Transactions</div>
              </div>
            </div>
            <ng-container *ngFor="let element of pendingStocks?.filteredData">
              <div class="more_action">
                <div class="row stock_container">
                  <div class="col-4">
                    <div class="f-14 fw-500 collapse_word">{{element.stock_name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Quantity</div>
                    <div class="f-14 fw-400 collapse_word"> {{ myMath.abs(element.stock_qty || 0) }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Proposed Date</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.proposed_date | date: 'dd MMM yyy' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Transaction Type</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.txn_type }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Transaction Status</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.txn_status }}</div>
                  </div>

                </div>
                <div style="display: flex;align-items: center;">
                  <span style="display: flex">
                    <ng-container *ngIf="!partnerList.includes(domainName)">
                      <img class="pointer" matTooltip="Edit" src="../../../../assets/images/icons/ep_edit_blue.svg"
                        style="width: 18px; height: 18px; margin-left: 20px" (click)="updateTransaction(element)" />
                    </ng-container>
                    <ng-container *ngIf="partnerList.includes(domainName)">
                      <img class="pointer" *ngIf="element?.txn_status !== 'PLACED'" matTooltip="Edit"
                        src="../../../../assets/images/icons/ep_edit_blue.svg"
                        style="width: 18px; height: 18px; margin-left: 20px" (click)="updateTransaction(element)" />
                    </ng-container>
                    <img *ngIf="element?.txn_status == 'PLACED' && !partnerList.includes(domainName)" class="pointer"
                      matTooltip="Check Status" src="../../../../assets/images/icons/bi_clipboard-check.svg"
                      style="width: 18px; height: 18px; margin-left: 20px" />
                  </span>
                </div>
              </div>
              <div class="divider_style"></div>
            </ng-container>
            <ng-container *ngIf="pendingStocks?.filteredData?.length === 0">
              <div class="grey_color">No Records Found.</div>
            </ng-container>
          </div>
       
        </div> 
        <table mat-table [dataSource]="pendingStocks" class="mt-25 is_web_view">
          <ng-container matColumnDef="title">
            <th class="f-14 heading-1 headingStyle" mat-header-cell *matHeaderCellDef [attr.colspan]="9">
              <div>Pending Transactions</div>
            </th>
          </ng-container>

          <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sr. No.</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stock_name">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style="text-align: left">
              Stocks
            </th>
            <td mat-cell *matCellDef="let element" class="stock-td">
              {{ element.stock_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Quantity
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ myMath.abs(element.stock_qty || 0) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Proposed_Date">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Proposed Date
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.proposed_date | date: 'dd MMM yyy' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="transaction_type">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="3">Transaction Type</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.txn_type }}
            </td>
          </ng-container>
          <ng-container matColumnDef="transaction_status">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="3">Transaction Status</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.txn_status }}
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Action
            </th>
            <td mat-cell *matCellDef="let element" class="text-center" style="width: 150px">
              <span style="display: flex; justify-content: space-evenly">
                <!-- <span

                  class="text-blue"

                  >Updatez hello</span
                > -->
                <!-- <img style="cursor: pointer" matTooltip="Update"  (click)="updateTransaction(element)" src="../../../../assets/images/icons/update-line.png"> -->
                <!-- <img style="cursor: pointer"  src="../../../../assets/images/icons/sync-ignored.png"> -->
                <img class="pointer" matTooltip="Edit" src="../../../../assets/images/icons/ep_edit_blue.svg"
                  style="width: 18px; height: 18px" (click)="updateTransaction(element)" />
                <!-- <img class="pointer" matTooltip="Delete" src="../../../../assets/images/icons/delete_blue.svg"
                  style="width: 18px; height: 18px" /> -->
                <img *ngIf="element?.txn_status === 'PLACED' && !partnerList.includes(domainName)" class="pointer"
                  matTooltip="Check Status" src="../../../../assets/images/icons/bi_clipboard-check.svg"
                  style="width: 18px; height: 18px" />
              </span>
            </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns3.length">
              No Pending Transaction Found.
            </td>
          </tr>

          <tr mat-header-row *matHeaderRowDef="['title']"></tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
          <tr class="f-14" mat-row *matRowDef="let row; columns: displayedColumns3"></tr>
        </table>
      </div>
      <div class="table-con">
        
        <div class="is_mobile_view">
          <div class="mt-10">
            <div class="performance_container" style="column-gap: 50px;">
              <div>
                <div class="f-14 fw-600">Stocks advised to Hold </div>
                <div class="f-12 fw-600 grey_color">(Current Pouch Composition)</div>
              </div>
              <div>
                <div class="f-14 fw-400" style="text-align: end;">Total Unrealized Profit / Loss %	</div>
                <div class="f-14 fw-600 text-end">
                  <span *ngIf="totalHoldPL > 0" class="text-right">
                    <img src="../../../../assets/images/stock_icons/green_positive.svg">{{ totalHoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
                  </span>
                  <span *ngIf="totalHoldPL < 0" class="error text-right">
                    <img src="../../../../assets/images/stock_icons/red_negative.svg"> ({{ myMath.abs(totalHoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
                  </span>
                </div>
              </div>
            </div>
            
            <ng-container *ngFor="let element of holdStocks">
              <div class="more_action">
                <div class="row stock_container">
                  <div class="col-4">
                    <div class="f-14 fw-500 collapse_word">{{element.stock_name}}</div>
                  </div>
                  <div class="col-4" *ngIf="radioSelected == 0">
                    <div class="f-12 fw-400 grey_color collapse_word">Quantity</div>
                    <div class="f-14 fw-400 collapse_word"> {{ myMath.abs(element.stock_qty || 0) }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Date</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.buy_date | date: 'mediumDate' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Price</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Current Value Date</div>
                    <div class="f-14 fw-400 collapse_word">              <ng-container *ngIf="radioSelected == 0">
                      {{ element.fact_date | date: 'mediumDate' }}
                    </ng-container>
                    <ng-container *ngIf="radioSelected != 0">
                      {{ (element?.sp_date | date: 'mediumDate') || '' }}
                    </ng-container></div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Current Value Price</div>
                    <div class="f-14 fw-400 collapse_word">{{ element.close_price | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Current Value Wt.%</div>
                    <div class="f-14 fw-400 collapse_word">{{ element?.current_wt }}</div>
                  </div>
                  <div class="col-4"  *ngIf="radioSelected == 0">
                    <div class="f-12 fw-400 grey_color collapse_word">Profit / Loss Amount</div>
                    <div class="f-14 fw-400 collapse_word">              <span *ngIf="element.unrealized_returns > 0">{{
                      element.unrealized_returns | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                      }}</span>
                    <span class="error" *ngIf="element.unrealized_returns < 0">
                      ({{
                      transformtPercentValue(element.unrealized_returns)
                      | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                      }})</span></div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Profit / Loss %</div>
                    <div class="f-14 fw-400 collapse_word">              <span *ngIf="element.price_change_unrealized > 0">{{
                      element.price_change_unrealized
                      }}</span>
                    <span class="error" *ngIf="element.price_change_unrealized < 0">
                      ({{ transformtPercentValue(element.price_change_unrealized) }})</span></div>
                  </div>
                </div>
                <div style="display: flex;align-items: center;">
                  <img class="pointer" *ngIf="element['partial_sale'] == 'N'" matTooltip="Delete"
                  src="../../../../assets/images/icons/delete_blue.svg" (click)="deleteStock(element, 'hold')"
                  style="width: 18px; height: 18px" />
                </div>
              </div>
              <div class="divider_style"></div>
            </ng-container>
            <ng-container *ngIf="holdStocks?.length === 0">
              <div class="grey_color">No Records Found.</div>
            </ng-container>
          </div>
       
        </div> 
        <table mat-table [dataSource]="holdStocks" class="mt-25 is_web_view">
          <ng-container matColumnDef="buy_date">
            <th class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element.buy_date | date: 'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="buy_rate">
            <th class="price-td" mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_date">
            <th class="price-td" class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="radioSelected == 0">
                {{ element.fact_date | date: 'mediumDate' }}
              </ng-container>
              <ng-container *ngIf="radioSelected != 0">
                {{ (element?.sp_date | date: 'mediumDate') || '' }}
              </ng-container>
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_rate">
            <th mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.close_price | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="cr_wt">
            <th mat-header-cell *matHeaderCellDef>Wt.%</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element?.current_wt }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sr. No.</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stock_name">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style="text-align: left">
              Stocks
            </th>
            <td mat-cell *matCellDef="let element" class="stock-td">
              {{ element.stock_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stock_wt">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              {{ radioSelected == 0 ? 'Quantity' : 'Wt.%' }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ radioSelected == 0 ? element.stock_qty : element.stock_wt }}
            </td>
          </ng-container>
          <ng-container matColumnDef="header-row-third-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Purchase
            </th>
          </ng-container>
          <ng-container matColumnDef="header-row-forth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              Current Value*
            </th>
          </ng-container>
          <ng-container matColumnDef="header-row-fifth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Profit / Loss
            </th>
          </ng-container>

          <ng-container matColumnDef="price_change_realized">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Profit / Loss %
            </th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_unrealized > 0">{{
                element.price_change_unrealized
                }}</span>
              <span class="error" *ngIf="element.price_change_unrealized < 0">
                ({{ transformtPercentValue(element.price_change_unrealized) }})</span>
            </td>
          </ng-container>

          <ng-container matColumnDef="profit-loss_amnt">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.unrealized_returns > 0">{{
                element.unrealized_returns | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }}</span>
              <span class="error" *ngIf="element.unrealized_returns < 0">
                ({{
                transformtPercentValue(element.unrealized_returns)
                | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="profit-loss_perc">
            <th mat-header-cell *matHeaderCellDef>%</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_unrealized > 0">{{
                element.price_change_unrealized
                }}</span>
              <span class="error" *ngIf="element.price_change_unrealized < 0">
                ({{ transformtPercentValue(element.price_change_unrealized) }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Action</th>
            <td class="text-center" mat-cell *matCellDef="let element">
              <img class="pointer" *ngIf="element['partial_sale'] == 'N'" matTooltip="Delete"
                src="../../../../assets/images/icons/delete_blue.svg" (click)="deleteStock(element, 'hold')"
                style="width: 18px; height: 18px" />
            </td>
          </ng-container>
          <ng-container matColumnDef="title">
            <th class="f-14 heading-2 headingStyle" mat-header-cell *matHeaderCellDef [attr.colspan]="12">
              Stocks advised to Hold ( Current Pouch Composition)
            </th>
          </ng-container>

          <ng-container matColumnDef="total">
            <td mat-footer-cell *matFooterCellDef colspan="8" style="text-align: right">
              {{ radioSelected == 0 ? 'Total' : 'Total Unrealized Profit / Loss %' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="total1">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalHoldPLAmnt > 0" class="text-right">
                {{ totalHoldPLAmnt | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalHoldPLAmnt < 0" class="error text-right">
                ({{ myMath.abs(totalHoldPLAmnt) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="total2">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalHoldPL > 0" class="text-right">
                {{ totalHoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalHoldPL < 0" class="error text-right">
                ({{ myMath.abs(totalHoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['title']"></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'header-row-fifth-group',
                    'action'
                  ]
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'price_change_realized'
                  ]
            "></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? displayedColumns
                : ['buy_date', 'buy_rate', 'sell_date', 'sell_rate', 'cr_wt']
            "></tr>
          <tr class="f-14" mat-row *matRowDef="
              let row;
              columns: radioSelected == 0
                ? displayedColumns1
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'buy_date',
                    'buy_rate',
                    'sell_date',
                    'sell_rate',
                    'cr_wt',
                    'price_change_realized'
                  ]
            "></tr>
          <tr mat-footer-row *matFooterRowDef="
              radioSelected == 0 ? ['total', 'total1', 'total2'] : ['total', 'total2']
            "></tr>
        </table>
      </div>

      <div class="table-con">
        
        <div class="is_mobile_view">
          <div class="mt-10">
            <div class="performance_container" style="column-gap: 50px;">
              <div>
                <div class="f-14 fw-600">Stocks advised to Sell</div>
              </div>
              <div>
                <div class="f-14 fw-400" style="text-align: end;">Total Realized Profit / Loss %</div>
                <div class="f-14 fw-600 text-end">
                  <span *ngIf="totalSoldPL > 0" class="text-right">
                    <img src="../../../../assets/images/stock_icons/green_positive.svg"> {{ totalSoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
                  </span>
                  <span *ngIf="totalSoldPL < 0" class="error text-right">
                    <img src="../../../../assets/images/stock_icons/red_negative.svg"> ({{ myMath.abs(totalSoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
                  </span>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let element of sellStocks">
              <div class="more_action">
                <div class="row stock_container">
                  <div class="col-4">
                    <div class="f-14 fw-500 collapse_word">{{element.stock_name}}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word"> {{ radioSelected == 0 ? 'Quantity' : 'Weightage %' }}</div>
                    <div class="f-14 fw-400 collapse_word">              {{
                      radioSelected == 0
                      ? myMath.abs(element.stock_qty)
                      : transformtValue(element.stock_wt)
                      }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Date</div>
                    <div class="f-14 fw-400 collapse_word">{{ element?.buy_date | date: 'mediumDate' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Purchase Price</div>
                    <div class="f-14 fw-400 collapse_word"> {{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Sale Date</div>
                    <div class="f-14 fw-400 collapse_word"> {{ element?.sell_date | date: 'mediumDate' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Sale Price</div>
                    <div class="f-14 fw-400 collapse_word"> {{ element?.sell_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}</div>
                  </div>
                  <div class="col-4">
                    <div class="f-12 fw-400 grey_color collapse_word">Profit / Loss</div>
                    <div class="f-14 fw-400 collapse_word">              <span *ngIf="element.price_change_realized > 0">{{
                      element.price_change_realized
                      }}</span>
                    <span class="error" *ngIf="element.price_change_realized < 0">
                      ({{ transformtPercentValue(element.price_change_realized) }})</span></div>
                  </div>
                </div>

              </div>
              <div class="divider_style"></div>
            </ng-container>
            <ng-container *ngIf="sellStocks?.length === 0">
              <div class="grey_color">No Records Found.</div>
            </ng-container>
          </div>
       
        </div> 
        <table mat-table [dataSource]="sellStocks" class="mat-elevation-z8 is_web_view">
          <ng-container matColumnDef="buy_date">
            <th class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.buy_date | date: 'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="buy_rate">
            <th class="price-td" mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element.buy_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_date">
            <th class="price-td" class="date-td" mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.sell_date | date: 'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sell_rate">
            <th mat-header-cell *matHeaderCellDef>Price</th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{ element?.sell_rate | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="sr">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Sr. No.</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="stock_name">
            <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" style="text-align: left">
              Stocks
            </th>
            <td mat-cell *matCellDef="let element" class="stock-td">
              {{ element.stock_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="stock_wt">
            <th class="weightage-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              {{ radioSelected == 0 ? 'Quantity' : 'Weightage %' }}
            </th>
            <td mat-cell *matCellDef="let element" class="text-right">
              {{
              radioSelected == 0
              ? myMath.abs(element.stock_qty)
              : transformtValue(element.stock_wt)
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="header-row-third-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Purchase
            </th>
          </ng-container>
          <ng-container matColumnDef="header-row-forth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Sale
            </th>
          </ng-container>
          <ng-container matColumnDef="price_change_realized">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
              Profit / Loss %
            </th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_realized > 0">{{
                element.price_change_realized
                }}</span>
              <span class="error" *ngIf="element.price_change_realized < 0">
                ({{ transformtPercentValue(element.price_change_realized) }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="header-row-fifth-group">
            <th style="text-align: center" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
              Profit / Loss
            </th>
          </ng-container>
          <ng-container matColumnDef="title">
            <th class="f-14 heading-1 headingStyle" mat-header-cell *matHeaderCellDef [attr.colspan]="10">
              Stocks advised to Sell
            </th>
          </ng-container>
          <ng-container matColumnDef="total">
            <td mat-footer-cell *matFooterCellDef colspan="7" style="text-align: right">
              {{ radioSelected == 0 ? 'Total' : 'Total Realized Profit / Loss %' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="total2">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalSoldPL > 0" class="text-right">
                {{ totalSoldPL | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalSoldPL < 0" class="error text-right">
                ({{ myMath.abs(totalSoldPL) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="total1">
            <td mat-footer-cell *matFooterCellDef colspan="1" class="text-right">
              <span *ngIf="totalSoldPLAmnt > 0" class="text-right">
                {{ totalSoldPLAmnt | currency: 'INR' : '' : '1.2-2' : 'en-IN' }}
              </span>
              <span *ngIf="totalSoldPLAmnt < 0" class="error text-right">
                ({{ myMath.abs(totalSoldPLAmnt) | currency: 'INR' : '' : '1.2-2' : 'en-IN' }})
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="profit-loss_amnt">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.realized_returns > 0">{{
                element.realized_returns | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }}</span>
              <span class="error" *ngIf="element.realized_returns < 0">
                ({{
                transformtPercentValue(element.realized_returns)
                | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="profit-loss_perc">
            <th mat-header-cell *matHeaderCellDef>%</th>
            <td class="text-right" mat-cell *matCellDef="let element">
              <span *ngIf="element.price_change_realized > 0">{{
                element.price_change_realized
                }}</span>
              <span class="error" *ngIf="element.price_change_realized < 0">
                ({{
                transformtPercentValue(element.price_change_realized)
                | currency: 'INR' : '' : '1.2-2' : 'en-IN'
                }})</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="action">
            <th class="profit-td" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Action</th>
            <td class="text-center" mat-cell *matCellDef="let element">
              <img class="pointer" matTooltip="Delete" src="../../../../assets/images/icons/delete_blue.svg"
                *ngIf="element?.rk == 1" (click)="deleteStock(element, 'sell')" style="width: 18px; height: 18px" />
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['title']"></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'header-row-fifth-group',
                    'action'
                  ]
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'header-row-third-group',
                    'header-row-forth-group',
                    'header-row-fifth-group'
                  ]
            "></tr>
          <tr mat-header-row *matHeaderRowDef="
              radioSelected == 0
                ? displayedColumns_old
                : ['buy_date', 'buy_rate', 'sell_date', 'sell_rate']
            "></tr>
          <tr class="f-14" mat-row *matRowDef="
              let row;
              columns: radioSelected == 0
                ? displayedColumns1_old
                : [
                    'sr',
                    'stock_name',
                    'stock_wt',
                    'buy_date',
                    'buy_rate',
                    'sell_date',
                    'sell_rate',

                    'price_change_realized'
                  ]
            "></tr>
          <tr mat-footer-row *matFooterRowDef="
              radioSelected == 0 ? ['total', 'total1', 'total2'] : ['total', 'total2']
            "></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end" *ngIf="parent && pouchId">
    <div class="col-2 text-end">
      <button class="theme-btn primary-btn" type="button" (click)="onNextClick($event)">
        Next
      </button>
    </div>
  </div>
</ng-container>

