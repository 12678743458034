<nav class="navigationText navContainer" mat-tab-nav-bar>
  <ng-container *ngFor="let link of navLinks">
    <ng-container *ngIf="!(link.label === 'Portfolio' && screenWidth < 600)">
      <a
      (click)="clickingOnRoute()"
      [ngClass]="isPublic ? 'navigationText_public' : ''"
      mat-tab-link
      *ngIf="showNavOption(link.label)"
      [routerLink]="link.link"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive">
      <span >{{ link.label }}</span>
    </a>
    </ng-container>

  </ng-container>

</nav>
