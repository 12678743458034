import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AuthService } from 'src/app/modules/auth/services/auth.service';

import { Logout } from '../store/actions/logout.action';

@Component({
  selector: 'app-header-public',
  templateUrl: './header-public.component.html',
  styleUrls: ['./header-public.component.scss']
})
export class HeaderPublicComponent implements OnInit {
  logginUser: string = '';
  isPublic: any = false;
  hideElement = true;
  role: any;
  isSideBarActive: boolean = false;
  istoggleActive: boolean = false;
  url: any;
  onlyPublic: boolean = false;
  smartbeta: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store
  ) {
    this.configRouterEvents();
  }

  ngOnInit() {
    this.role = '';
    this.authService.onUserUpdate.subscribe((data) => {
      this.logginUser = `${data.attributes?.name?.first} ${data.attributes?.name?.last}`;
      this.role = data.attributes.role;
    });

    if (!this.logginUser) {
      const storageUser: any = localStorage.getItem('user');
      const user = JSON.parse(storageUser);
      if (user) {
        this.logginUser = `${user.attributes?.name?.first} ${user.attributes?.name?.last}`;
        this.role = user.attributes.role;
      }
    }
    // if (this.router.url.includes('/public/pouchlist-details')) {
    //   this.hideElement = true;
    // }
  }

  private configRouterEvents() {
    let routes: any = [
      '/login',
      '/auth',
      '/auth/login',
      '/auth/new-password',
      // '/onboarding',
      // '/onboarding/questionaire',
      // '/onboarding/multiplechoice',
      // '/onboarding/declaration',

      '/auth/register',
      '/auth/otp',
      '/auth/forget-password',

      '/onboarding/questionaire/smartbeta',
      '/onboarding/questionaire/smartvalue',
      '/onboarding/kyc_upload/smartbeta',
      '/agreement/smartbeta',
      '/auth/register/smartbeta',
      '/public',
      '/public/landing-page',
      '/public/privacy-policy',
      '/public/terms-and-conditions',
      '/public/pouchlist',
      '/public/pouchlist-details'
    ];
    setTimeout(() => {
      this.url = this.router.url;
      // this.hideElement = routes.includes(this.url) ? true : false;

      for (let i = 0; i < routes.length; i++) {
        if (this.url.includes(routes[i])) {
          this.hideElement = true;
          break;
        } else {
          this.hideElement = false;
        }
      }
      if (
        this.url == '/onboarding/kyc_upload/smartbeta' ||
        this.url == 'onboarding/questionaire/smartbeta'
      ) {
        this.smartbeta = true;
      } else {
        this.smartbeta = false;
      }
      // routes.forEach((element : any) => {
      //   this.hideElement = this.url.includes(element) ? true : false;
      // });

      // if (this.router.url.includes('/public/subscription-plan')) {
      //   const storageUser: any = localStorage.getItem('user');
      //   this.hideElement = storageUser ? false : true;
      // }
      if (this.router.url.indexOf('redirect/map-subscriber') > -1) {
        this.hideElement = true;
      }
    }, 100);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.hideElement = routes.includes(event.url) ? true : false;

        if (event.url.includes('/public/pouchlist-details')) {
          this.hideElement = true;
        }
      }

      // if (this.router.url.includes('/public/subscription-plan')) {
      //   const storageUser: any = localStorage.getItem('user');
      //   this.hideElement = storageUser ? false : true;
      // }
    });

    if (this.router.url.indexOf('/dashboard') > -1) {
      this.hideElement = false;
    }

  }

  togglesideBar() {
    this.isSideBarActive = !this.isSideBarActive;
    this.istoggleActive = !this.istoggleActive;
  }
  onLogout(): void {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    const Component: any = {
      richmee: '/auth/login',
      kandkfinserv: '/auth/login',
      dhan: '/auth/login',
      bajajbroking: '/',
      wealthy: '/',

    };
    const refreshToken: any = localStorage.getItem('refreshToken');
    const id: any = localStorage.getItem('id');
    this.authService.getTokens(refreshToken, id).subscribe((response) => {
      this.authService.setTokens(response);
      this.authService.logoutUser().subscribe((res: any) => {
        this.store.dispatch(new Logout());
        localStorage.clear();

        if (subdomain && Component[subdomain]) {
          this.router.navigateByUrl(Component[subdomain]);
        } else {
          this.router.navigateByUrl('/auth/login');
        }

      });
    });

    if (subdomain && Component[subdomain]) {
      this.router.navigateByUrl(Component[subdomain]);
    } else {
      this.router.navigateByUrl('/auth/login');
    }
    localStorage.clear();
  }
  changePassword(): void {
    this.router.navigateByUrl('/auth/change-password');
  }
  closeMenu1(e: any) {
    this.isSideBarActive = false;
    this.istoggleActive = false;
  }
  goTo() {
    if (this.logginUser) {
      this.router.navigateByUrl('/dashboard');
    } else {
      const host = window.location.host;
      const subdomain = host.split('.')[0];

      if (subdomain === 'richmee' || subdomain == 'kandkfinserv' || subdomain == 'nirjainvestments') {
        this.router.navigateByUrl('/');
      } else {
        this.router.navigateByUrl('/public/landing');
      }
    }
  }
  goToProfile() {
    if (this.role == 'subscriber') {
      this.router.navigateByUrl('/profile');
    }
  }
  domain = () => {
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    if (subdomain === 'richmee') {
      return 'richmee';
    }
    else if (subdomain=='kandkfinserv') {
        return 'kandkfinserv'
    }
    else if (subdomain=='nirjainvestments') {
        return 'nirjainvestments'
    }
    else if (subdomain === 'bajajbroking') {
      return 'bajajbroking';
    } else if (subdomain === 'wealthy') {
      return 'wealthy';
    } else {
      return '';

    }
  };
}
