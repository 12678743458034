import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PublicService } from '../../services/public.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent implements OnInit {
  public bgColor: boolean;

  public hideHeader: boolean;
  richmeeHeader: boolean = false;
    kandkfinservHeader : boolean = false;
  constructor(private router: Router,private publicService:PublicService) {
    this.router.events.subscribe((event) => {
      let routes = [
        '/',
        '/public/landing-page',
        '/public/dashboard',
        'public/privacy-policy',
        '/public/terms-and-conditions'
      ];

      if (event instanceof NavigationEnd) {
        if (
          event.url === '/' ||
          event.url === '/public/landing-page' ||
          event.url === '/public' ||
          event.url === 'public/privacy-policy' ||
          event.url === '/public/terms-and-conditions'
        ) {
          this.bgColor = true;
        } else {
          this.bgColor = false;
        }
        if (
          event.url === '/public/dashboard' ||
          event.url === '/public/pouch-creation' ||
          event.url === 'public/privacy-policy' ||
          event.url === '/public/terms-and-conditions'
        ) {
          this.hideHeader = true;
          this.bgColor = false;
        } else {
          this.hideHeader = false;
          this.bgColor = true;
        }
      }
    });
  }
  nirjainvestmentsHeader:boolean = false
  ngOnInit(): void {
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    if (subdomain === 'richmee') {
      this.richmeeHeader = true;
      this.kandkfinservHeader = false;
      this.nirjainvestmentsHeader = false
      return;
    } else if (subdomain=='kandkfinserv') {
      this.kandkfinservHeader = true
      this.richmeeHeader = false;
      this.nirjainvestmentsHeader = false

    }else if (subdomain=='nirjainvestments') {
      this.kandkfinservHeader = false
      this.richmeeHeader = false;
      this.nirjainvestmentsHeader = true

    }
     else {
      this.richmeeHeader = false;
      this.kandkfinservHeader = false;
    }
  }

  onLogin() {
    this.router.navigateByUrl('/auth/login');
  }
  onRegister() {
    this.router.navigateByUrl('/auth/register');
  }
  goToHome() {
    // this.router.navigateByUrl('/')
    location.href = '/';
  }
  environment =environment
  showPouch(token: any, id: any) {
    // let payload = {
    //   "token": token,
    //   "pouch_id": Number(id)
    // }
    // this.publicService.showPouch(payload).subscribe(response => {
    //   const redirectUrl = response.headers.get('Location');
    //  
    // }, error => {
    
    // });
  }
  pouchId=''
  token=''
}
