import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { take } from 'rxjs/operators';

import { cloneDeep } from 'lodash';
import { SharedService } from 'src/app/common/services/shared.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PublicService } from 'src/app/modules/public/services/public.service';
import { NotificationService } from 'src/app/services/notification.services';

import { OnBoardingService } from '../../services/onboarding.service';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  @ViewChild('ngMobileOtpInput') ngMobileOtpInputRef: any;
  @ViewChild('ngEmailOtpInput') ngEmailOtpInputRef: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private router: Router,
    public dialogRef: MatDialogRef<OtpComponent>,
    public onboardingServices: OnBoardingService,
    private notifyService: NotificationService,
    private authService: AuthService,
    private publicService: PublicService,
    private sharedService: SharedService
  ) {

  }
  resendCounter: number = 0;
  showResendLink: boolean = false;
  mobileOtp: string;
  emailOtp: string;
  otpError: boolean;
  config = {
    allowNumbersOnly: true,
    length: 6,
    inputStyles: {
      color: 'rgba(0, 0, 0, 0.3)',
      fontSize: '25px'
    }
  };
  access_token:any
 

  ngOnInit(): void {
    localStorage.clear();
    this.otpError = false;
    this.timer();
    this.access_token = this.data.access_token

    if(this.data.from === 'login'){
    this.verifyOnLogin()      
    }else{
      this.showBothOtp = true
    }
  }

  timer() {
    setTimeout(() => {
      this.showResendLink = true;
    }, 300000);
  }
  onMobileOtpChange(otp: any) {
    this.mobileOtp = otp;
  }
  onEmailOtpChange(otp: any) {
    this.emailOtp = otp;
  }
  resendOTP() {
    this.resendCounter++;
    if (this.resendCounter <= 3) {
      this.onboardingServices.generateOTP(this.data.mobile).subscribe((res: any) => {
        if (res.status === 'success') {
          this.notifyService.showSuccess(
            `SUCCESS : New OTP has been sent to your mobile number`,
            ''
          );
        }
      });
    }
  }
  submit(type:any){
    if(type ==='mobile'){
      let body = {
      code: this.mobileOtp,
      id: `+91-${this.data.mobile.toString().substring(this.data.mobile.toString().length - 10)}`
    };
    this.verifyOtp(body,type)
    }
    if(type ==='email'){
      let body = {
        code: this.emailOtp,
        id:this.data.email
      };
      this.verifyOtp(body,type)
    }
  }
  mobileError:boolean = false
  verifyMobile:boolean = false
  emailError:boolean = false
  verifyEmail:boolean = false
  ngDoCheck(){
  const {attributes} =this.authService.getParsedToken()?.identity;
  if(Object.keys(attributes).length>0){
    this.verifyEmail=attributes?.email_verified?true:false;
    this.verifyMobile=attributes?.mobile_verified?true:false;
  }

  }
  verifyOtp(body:any,type:any) {


    if (this.data.product === 'smartbeta') {
      if (this.data.type === 'old') {
        this.onboardingServices.verifyOTP(body,this.access_token).subscribe((res: any) => {
          try {
            if (res[0]['otp'][0]['otp_id']) {
              this.smartBetaLoad(res);
            } else {
              this.otpError = true;
            }
          } catch (error) {
            this.otpError = true;
          }
        });
      }
      if (this.data.type === 'new') {
        this.onboardingServices.verifyOTP(body,this.access_token).subscribe((res: any) => {
          this.access_token = res.id_token
          this.authService.setTokens(res);
          let decodedToken: any = jwtDecode(res.id_token);
          localStorage.setItem('id', decodedToken?.identity.id || '');
          if(type === 'mobile'){
            if(res){
              this.mobileError = false
              this.verifyMobile = true}
          }
          if(type === 'email'){
            if(res){
              this.emailError = false
              this.verifyEmail = true
            }
          }
          this.onboardingServices
            .registerSubscriber(this.onboardingServices.createSubscriberData)
            .subscribe((res: any) => {

              if (res[0]['otp'][0]['otp_id']) {
                this.smartBetaLoad(res);
              } else {
                this.otpError = true;
              }
            });
        },(e:any)=>{
          if(e){
            if(type === 'mobile'){
              this.mobileError = true
            }
            if(type === 'email'){
              this.emailError = true

            }
          }
        });
      }
    } else {
      this.onboardingServices.verifyOTP(body,this.access_token).subscribe(
        (res: any) => {
          this.access_token = res.id_token
          if(type === 'mobile'){
            if(res){
              
              this.authService.setTokens(res);
              let decodedToken: any = jwtDecode(res.id_token);
              localStorage.setItem('id', decodedToken?.identity.id || '');
              this.authService.fetchUser(res.access_token, res.id_token).subscribe((res) => {
                localStorage.setItem('user', JSON.stringify(res));
                this.authService.setUser(res);
              });
              this.mobileError = false
              this.verifyMobile = true}
          }
          if(type === 'email'){
            if(res){
              this.authService.setTokens(res);
              let decodedToken: any = jwtDecode(res.id_token);
              localStorage.setItem('id', decodedToken?.identity.id || '');
              this.authService.fetchUser(res.access_token, res.id_token).subscribe((res) => {
                localStorage.setItem('user', JSON.stringify(res));
                this.authService.setUser(res);
              });
              this.emailError = false
              this.verifyEmail = true
            }
          }
          let isValidOTP;

          try {
            isValidOTP = res[0]['otp'][0]['otp_id'];
          } catch (error) {
            isValidOTP = false;
          }
          if(this.verifyMobile && this.verifyEmail){
            isValidOTP = true
            this.dialogRef.close();
          }else{
            isValidOTP = false
          }
          if (isValidOTP) {
            this.dialogRef.close();
            if (this.data.product === 'smartbeta') {
              this.router.navigateByUrl(`/onboarding/questionaire/${this.data.product}`);
            } else {
              
              // this.onboardingServices
              //   .registerSubscriber(this.onboardingServices.createSubscriberData)
              //   .subscribe(
              //     (res: any) => {
              //       this.authService.setTokens(res);
              //       this.authService.fetchUser(res.access_token, res.id_token).subscribe((res) => {
              //         localStorage.setItem('user', JSON.stringify(res));
              //         this.authService.setUser(res);

              //         this.router.navigateByUrl('/dashboard');
              //       });
              //     },
              //     (e) => {
              //       this.notifyService.showError('', e.error.error.message);
              //     }
              //   );
              this.router.navigateByUrl('/dashboard');
            }
          } else {
            this.otpError = true;
          }
        },
        (error) => {
          if(error){
            if(type === 'mobile'){
              this.mobileError = true
            }
            if(type === 'email'){
              this.emailError = true

            }
          }
          this.notifyService.showError('Error', 'Something went wrong');
        }
      );
    }
  }
  checkExpiry(Cdate: any) {
    let quoteExpiry = new Date(Cdate.setFullYear(Cdate.getFullYear() + 1));
    let now = new Date();

    if (now.getTime() > quoteExpiry.getTime()) {
      return true;
    } else {
      return false;
    }
  }
  smartBetaLoad(res1: any) {
    this.onboardingServices
      .save_subscriber_pan({
        id: this.data.detail.id,
        subscriber_first_name: this.data.detail.subscriber_first_name.value,
        subscriber_last_name: this.data.detail.subscriber_last_name.value,
        subscriber_middle_name: this.data.detail.subscriber_middle_name.value,
        subscriber_pan: this.data.detail.subscriber_pan,
        subscriber_email: this.data.detail.subscriber_email
      })
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.authService.setTokens(res);
          this.authService.fetchUser(res.access_token, res.id_token).subscribe((res) => {
            let resCopy: any = cloneDeep(res);
            resCopy.attributes.pan = this.data.detail.subscriber_pan;
            localStorage.setItem('user', JSON.stringify(resCopy));
            this.authService.setUser(resCopy);
            let userInfo = JSON.parse(localStorage.getItem('user') || '{}').attributes;
            this.publicService.agreementDetailsNew = {
              investment_amount: this.onboardingServices.betaInvestment,
              registration_date: new Date().toISOString().split('T')[0],
              product_name: 'smartbeta',
              agreementValues: this.data.agreementValues
            };
            sessionStorage.setItem(
              'agreementSmartBeta',
              JSON.stringify(this.publicService.agreementDetailsNew)
            );
            if (
              userInfo?.risk_profile === 'PENDING' ||
              this.checkExpiry(new Date(userInfo?.risk_profile_date))
            ) {
              this.dialogRef.close();
              this.router.navigateByUrl(`/onboarding/questionaire/${this.data.product}`);
              return;
            } else {
              if (userInfo?.kyc_date === '' || this.checkExpiry(new Date(userInfo?.kyc_date))) {
                this.dialogRef.close();
                this.router.navigateByUrl('/onboarding/kyc_upload/smartbeta');
                return;
              } else {
                this.publicService.agreementFrom = 'from-create';
                this.publicService.agreementDetailsNew = {
                  investment_amount: this.onboardingServices.betaInvestment,
                  registration_date: new Date().toISOString().split('T')[0],
                  product_name: 'smartbeta',
                  agreementValues: this.data.agreementValues
                };
                sessionStorage.setItem(
                  'agreementSmartBeta',
                  JSON.stringify(this.publicService.agreementDetailsNew)
                );
                this.dialogRef.close();
                this.router.navigateByUrl('/agreement/smartbeta');
              }
            }
          });
        },
        (e: any) => {
          this.notifyService.showError(`Something went wrong :Try Again`, '');
        }
      );
  }
  generateOtp(payload:any){
    this.onboardingServices.generateMobileOTP(payload,this.authService.getIdToken()).subscribe((res:any)=>{})
  }
  showBothOtp:boolean = false
  verifyOnLogin(){
    let userData = this.data.userattributes
    this.access_token = this.authService.getIdToken()
    if(!userData?.mobile_verified){
      this.data.mobile = userData?.mobile
      let payload = {
        username: userData?.mobile,
        credential_type: 'mobile'
       }
       this.generateOtp(payload)
    }
    if(!userData?.email_verified){
      this.data.email = userData?.email
      let payload = {
        username: userData?.email,
        credential_type: 'email'

       }
       this.generateOtp(payload)
    }
    if(!userData?.mobile_verified && !userData?.email_verified){
      this.showBothOtp = true
    }
  }
}
